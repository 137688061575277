import uzayLogo from "../../../../assets/games/uzayLogo.webp";
import kutupLogo from "../../../../assets/games/kutupLogo.webp";
import ciftlikLogo from "../../../../assets/games/ciftlikLogo.webp";
import codaliLogo from "../../../../assets/games/codaliLogo.webp";
// import galaksiLogo from "../../../../assets/games/galaksiLogo.webp";
import startLogo from "../../../../assets/games/uzayStartIcon.svg";
import closeLogo from "../../../../assets/games/uzayCloseIcon.svg";

import ciftlik_and_or from "../../../../assets/games/gifs/ciftlik_and_or.gif";
import ciftlik_boolean from "../../../../assets/games/gifs/ciftlik_boolean.gif";
import ciftlik_degişkenler from "../../../../assets/games/gifs/ciftlik_degişkenler.gif";
import ciftlik_fonksiyonlar_1 from "../../../../assets/games/gifs/ciftlik_fonksiyonlar_1.gif";
import ciftlik_fonksiyonlar_2 from "../../../../assets/games/gifs/ciftlik_fonksiyonlar_2.gif";
import ciftlik_for_dongusu from "../../../../assets/games/gifs/ciftlik_for_dongusu.gif";
import ciftlik_karsilastirma from "../../../../assets/games/gifs/ciftlik_karsilastirma.gif";
import ciftlik_yeni_bloklar from "../../../../assets/games/gifs/ciftlik_yeni_bloklar.gif";
import CodeAli_temeller_1 from "../../../../assets/games/gifs/CodeAli-temeller-1.gif";
import CodeAli_temeller_2 from "../../../../assets/games/gifs/CodeAli-temeller-2.gif";
import CodeAliDonguler1 from "../../../../assets/games/gifs/CodeAliDonguler1.gif";
import CodeAliDonguler2 from "../../../../assets/games/gifs/CodeAliDonguler2.gif";
import CodeAliKosullu_ifadeler_if_else from "../../../../assets/games/gifs/CodeAliKosullu-ifadeler-if-else.gif";
import CodeAliWhileDongusu from "../../../../assets/games/gifs/CodeAliWhileDongusu.gif";
import kutup_hata from "../../../../assets/games/gifs/kutup_hata.gif";
import kutup_ic_ice_donguler from "../../../../assets/games/gifs/kutup_ic_ice_donguler.gif";
import kutup_if_else from "../../../../assets/games/gifs/kutup_if_else.gif";
import kutup_if_kosulu from "../../../../assets/games/gifs/kutup_if_kosulu.gif";
import kutup_while_dongusu_1 from "../../../../assets/games/gifs/kutup_while_dongusu_1.gif";
import kutup_while_dongusu_2 from "../../../../assets/games/gifs/kutup_while_dongusu_2.gif";
import uzay_donguler_1 from "../../../../assets/games/gifs/uzay_donguler_1.gif";
import uzay_donguler_2 from "../../../../assets/games/gifs/uzay_donguler_2.gif";
import uzay_donguler_3 from "../../../../assets/games/gifs/uzay_donguler_3.gif";
import uzay_hata_ayiklama from "../../../../assets/games/gifs/uzay_hata_ayiklama.gif";
import uzay_temeller_1 from "../../../../assets/games/gifs/uzay_temeller_1.gif";
import uzay_temeller_2 from "../../../../assets/games/gifs/uzay_temeller_2.gif";

export const getModalContentByURL = (url) => {
  if (
    url.includes("games/1/sections/1/steps") ||
    url.includes("games/1/sections/2/steps")
  ) {
    return {
      image: uzayLogo,
      title: "HAREKETLER",
      description: [
        `Hareketler, kodlamada tıpkı bir labirentte doğru yolları seçmek gibidir. Hedefe ulaşmak için nereye döneceğini ve kaç adım atacağını iyi bilmen gerekir. Hareketler, bir karakteri ya da nesneyi istediğin yere götürmenin en eğlenceli yoludur!`,
        "Örneğin: “Sağa dön ve 5 adım ilerle” dersen, karakter tam olarak bunu yapar. Ama yanlış bir komut verip “Geri git” dersen, hedefinden uzaklaşabilirsin!",
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/1/sections/3/steps") ||
    url.includes("games/1/sections/37/steps") ||
    url.includes("games/1/sections/38/steps")
  ) {
    return {
      image: uzayLogo,
      title: "DÖNGÜLER",
      description: `Döngüler, tıpkı bir şarkının nakaratı gibi, kodunuzda belirli bir kod bloğunu tekrar tekrar çalıştırmak için kullanılan sihirli araçlardır. Döngüler, tekrarlanan görevleri otomatikleştirmek için mükemmeldirler ve kodunuzu daha kısa ve daha verimli hale getirirler. Mesela bir karakterin 10 kere zıplamasını istiyorsan, döngü kullanabilirsin. Böylece 10 kere "zıpla" yazmak zorunda kalmazsın, bir kere döngüye yazarsın ve karakter 10 kere zıplar!`,
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/1/sections/4/steps")) {
    return {
      image: uzayLogo,
      title: "HATA AYIKLAMA",
      description: [
        `Hata ayıklama, tıpkı bir dedektif gibi çalışmayı gerektirir. Gemisinde sorun fark eden Codemino şöyle düşündü:`,
        "1. Adımları Kontrol Et: “Kodumda hangi adımı yanlış yazdım?”",
        `2. İzleme Yap: Kodun her satırını takip etti, nelerin doğru, nelerin yanlış çalıştığını gözlemledi.`,
        `3. Deneme ve Düzeltme: Yanlış bir komut (“sağa dön” yerine “geri dön”) buldu ve hemen düzeltti.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/2/sections/5/steps")) {
    return {
      image: kutupLogo,
      title: "İÇ İÇE DÖNGÜLER",
      description: [
        `İç içe döngüler, tıpkı kutuptaki karla kaplı bir alanda her bir adımı takip etmek gibidir. Dış döngü her bir bölgeyi keşfederken, iç döngü her bölgedeki her adımı kontrol eder.`,
        ``,
        `Örneğin: Dış döngü: Her bir kutup alanını keşfedin. İç döngü: Her alandaki adımları doğru şekilde uygula.`,
        `Codemino, iç içe döngülerle her bir kar parçasını dikkatlice taradı. Bu sayede her bölgedeki tüm alanları tek tek kontrol edebildi.`,
        ``,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/2/sections/6/steps") ||
    url.includes("games/2/sections/9/steps")
  ) {
    return {
      image: kutupLogo,
      title: "WHILE DÖNGÜSÜ",
      description: [
        `While döngüsü, bir şey doğru olduğu sürece bir işi yapmaya devam etmeyi sağlar.`,
        `Codemino, izleri takip ederken şu şekilde düşündü:`,
        `“Eğer izleri buluyorsak, takip etmeye devam et!” Yani, while döngüsü şöyle çalıştı:`,
        `Koşul: Eğer iz hala var ise, devam et.`,
        `İşlem: İzleri takip et, her adımı kontrol et. Codemino ve ekibi, izleri takip ederek kaybolan ekibe ulaştılar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/2/sections/7/steps") ||
    url.includes("games/2/sections/8/steps")
  ) {
    return {
      image: kutupLogo,
      title: "IF ELSE KOŞULU",
      description: [
        `Codemino ve ekibi kutupta iki yol arasında kaldılar; biri karla, diğeri buzla kaplıydı. Hangi yolu seçeceklerine karar vermek için if-else koşulu kullandılar.`,
        `If-else, bir duruma göre iki seçenek sunar. Codemino şöyle düşündü: Eğer yol karla kaplıysa, devam et.`,
        `Eğer buzla kaplıysa, geri dön ve başka bir yol dene.`,
        `Sonunda, karla kaplı yoldan giderek güvenli bir şekilde ilerlediler.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/2/sections/10/steps")) {
    return {
      image: kutupLogo,
      title: "HATA AYIKLAMA",
      description: [
        `Hata ayıklama, tıpkı bir dedektif gibi çalışmayı gerektirir.`,
        `Gemisinde sorun fark eden Codemino şöyle düşündü:`,
        `1. Adımları Kontrol Et: “Kodumda hangi adımı yanlış yazdım?”`,
        `2. İzleme Yap: Kodun her satırını takip etti, nelerin doğru, nelerin yanlış çalıştığını gözlemledi.`,
        `3. Deneme ve Düzeltme: Yanlış bir komut (“sağa dön” yerine “geri dön”) buldu ve hemen düzeltti.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/11/steps")) {
    return {
      image: ciftlikLogo,
      title: "YENi BLOKLAR",
      description: [
        `Codemino ve arkadaşları, büyük bir çiftlikte çeşitli görevleri yerine getirmek için bir maceraya atıldılar. Çiftlikte, tarlaların ekilmesinden, balyaların toplanmasına kadar birçok iş vardı. Codemino, çiftlik işlerini düzenli ve verimli bir şekilde yapmak için farklı kodlama araçları kullandı.`,
        `Çiftlik macerasında, Codemino her adımda kodlama ve problem çözme yeteneklerini kullanarak işleri kolaylaştırdı ve çiftlikteki tüm görevleri başarıyla tamamladı.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/12/steps")) {
    return {
      image: ciftlikLogo,
      title: "BOOLEAN DEĞERLER",
      description: [
        `Boolean değerler, sadece iki olası durumu ifade eder: True (doğru) veya False (yanlış). Yani, bir şeyin doğru olup olmadığını kontrol etmek için boolean değerleri çok işe yarar. Codemino, çiftlikte şu şekilde kullanıyordu:`,
        `Tavuk Yumurtladı Mı? Eğer tavuk yumurtladıysa: true Eğer tavuk yumurtlamadıysa: false`,
        `Boolean değerleri, karar verme ve koşul kontrolü için çok önemlidir ve bu sayede çiftlikte işler kolayca halledildi! 🌾`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/13/steps")) {
    return {
      image: ciftlikLogo,
      title: "DEĞİŞKENLER",
      description: [
        `Codemino ve ekibi, çiftlikteki işlerini yaparken birçok farklı değişken kullandılar.`,
        `Değişkenler, bilgi saklamak ve gerektiğinde bu bilgileri kullanmak için çok önemli araçlardır.`,
        `Çiftlikte her şey bir değişkene dönüşebilir: hayvanların sayısı, tarladaki ürünler, hava durumu gibi`,
        `Codemino ve ekibi, değişkenleri kullanarak çiftlikteki işleri verimli bir şekilde halletti! 🌾🌟`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/14/steps")) {
    return {
      image: ciftlikLogo,
      title: "FOR DÖNGÜSÜ",
      description: [
        `Codemino ve ekibi, çiftlikteki tekrarlanan işlerini kolaylaştırmak için for döngüsü kullandılar.  For döngüsü, belirli bir işlemi belirli bir sayıda tekrarlamak için kullanılır.`,
        `Bu sayede, aynı işi defalarca yazmak yerine tek bir komutla işi halledebilirler. Örneğin her sabah tavukları kümesten almak gerekiyordu. Codemino, 10 tavuğu almak için for döngüsü kullandı: Bu döngü, her bir tavuk için aynı işlemi tekrar etti ve zaman kazandırdı.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/15/steps")) {
    return {
      image: ciftlikLogo,
      title: "AND / OR",
      description: `
Codemino ve ekibi, çiftlikte bazı görevleri yaparken birden fazla koşulu aynı anda kontrol etmek zorunda kaldılar.
AND (VE): İki koşulun her ikisinin de doğru olması gerektiğinde kullanılır. Örneğin: Codemino, "Eğer hava güneşliyse ve tarla kuruysa, sulama yap" dedi. Yani her iki koşulun da doğru olması gerekiyordu.
OR (VEYA): Koşullardan birinin doğru olması yeterli olduğunda kullanılır. Örneğin: Codemino, "Eğer inekler açsa ya da suyu bitmişse, onları besle" dedi. Yani bir koşul yeterliydi.`,
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/3/sections/16/steps") ||
    url.includes("games/3/sections/18/steps")
  ) {
    return {
      image: ciftlikLogo,
      title: "FONKSİYONLAR",
      description: `Fonksiyonlar, bir görevi tekrar tekrar yapmak yerine, bir kere yazıp her zaman kullanabileceğiniz kod bloklarıdır.
Codemino’nun çiftlikte kullandığı bir fonksiyon tavukları toplamak içindi. Tavukları Toplama Fonksiyonu:
Codemino, her sabah tavukları kümesten almak için bir fonksiyon yazdı.  Bu fonksiyon, her sabah tavukları almak için aynı komutları yazmasına gerek kalmadan her seferinde çalıştı. Fonksiyonlar, kodu daha düzenli, verimli ve tekrar kullanılabilir hale getirir. `,
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/3/sections/17/steps")) {
    return {
      image: ciftlikLogo,
      title: "KARŞILAŞTIRMA",
      description: [
        `Codemino ve ekibi, çiftlikteki işleri yaparken 
bazı koşulları karşılaştırarak doğru kararlar almaları gerekiyordu.`,
        `Karşılaştırmalar, iki değeri karşılaştırarak hangisinin küçük, büyük, eşit veya doğru olup olmadığını belirlememize yardımcı olur.`,
        `Karşılaştırmalar, doğru kararları almayı ve çiftlikteki işleri düzenlemeyi sağlar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/4/sections/19/steps") ||
    url.includes("games/4/sections/20/steps")
  ) {
    return {
      image: codaliLogo,
      title: "TEMELLER",
      description: [
        `Kodlamada yönler ve hareketler, tıpkı bir labirentte doğru yolu bulmaya benzer. Hedefe ulaşmak için doğru yönü seçmeli ve doğru adımları atmalısın.`,
        `Hareketler, bir karakteri ya da nesneyi istediğin yere taşımak için çok önemlidir! Örneğin, "Sağa dön ve 5 adım ilerle" dersen, karakterin tam olarak bunu yapar.`,
        `Ama "Geri git" dersen, yanlış yola gidebilirsin! Hareketleri doğru planlarsan, hedefe kolayca ulaşabilirsin.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (
    url.includes("games/4/sections/21/steps") ||
    url.includes("games/4/sections/22/steps")
  ) {
    return {
      image: codaliLogo,
      title: "DÖNGÜLER",
      description: [
        `Döngüler, tıpkı bir şarkının nakaratı gibi, kodunuzda belirli bir kod bloğunu tekrar tekrar çalıştırmak için kullanılan sihirli araçlardır.`,
        `Döngüler, tekrarlanan görevleri otomatikleştirmek için mükemmeldirler ve kodunuzu daha kısa ve daha verimli hale getirirler. Mesela bir karakterin 10 kere zıplamasını istiyorsan, döngü kullanabilirsin.`,
        `Böylece 10 kere "zıpla" yazmak zorunda kalmazsın, bir kere döngüye yazarsın ve karakter 10 kere zıplar!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/4/sections/23/steps")) {
    return {
      image: codaliLogo,
      title: "WHILE DÖNGÜSÜ",
      description: [
        `While döngüsü, bir şey doğru olduğu sürece bir işi yapmaya devam etmeyi sağlar.`,
        `CodeAli, izleri takip ederken şu şekilde düşündü:`,
        `“Eğer izleri buluyorsak, takip etmeye devam et!” Yani, while döngüsü şöyle çalıştı:`,
        `Koşul: Eğer iz hala var ise, devam et.`,
        `İşlem: İzleri takip et, her adımı kontrol et. CodeAli ve ekibi, izleri takip ederek kaybolan ekibe ulaştılar.`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  } else if (url.includes("games/4/sections/24/steps")) {
    return {
      image: codaliLogo,
      title: "KOŞULLU İFADELER",
      description: [
        `CodeAli ormanda iki yol arasında kaldı; biri ağaçlarla, diğeri çamurla kaplıydı.  If-else koşulunu kullanarak doğru yolu seçmeye karar verdiler.`,
        `If-else şöyle çalıştı: Eğer yol ağaçla kaplıysa, devam et. Eğer çamurla kaplıysa, başka bir yol dene.`,
        `CodeAli, ağaçla kaplı yolu seçerek güvenli bir şekilde ilerledi. If-else koşulu, doğru kararı almayı sağlayarak programın esnek ve doğru çalışmasını temin etti!`,
      ],
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  }
  // else if (url.includes("games/6/sections/-----/steps")) {
  //   return {
  //     image: galaksiLogo,
  //     title: "KOŞULLU İFADELER",
  //     description: [
  //       `CodeAli ormanda iki yol arasında kaldı; biri ağaçlarla, diğeri çamurla kaplıydı.  If-else koşulunu kullanarak doğru yolu seçmeye karar verdiler.`,
  //       `If-else şöyle çalıştı: Eğer yol ağaçla kaplıysa, devam et. Eğer çamurla kaplıysa, başka bir yol dene.`,
  //       `CodeAli, ağaçla kaplı yolu seçerek güvenli bir şekilde ilerledi. If-else koşulu, doğru kararı almayı sağlayarak programın esnek ve doğru çalışmasını temin etti!`,
  //     ],
  //     closeIcon: closeLogo,
  //     startButton: startLogo,
  //   };
  // }
  else {
    return {
      image: uzayLogo,
      title: "Genel Bölüm",
      description: "Bu bölüm hakkında daha fazla bilgi yakında eklenecek.",
      closeIcon: closeLogo,
      startButton: startLogo,
    };
  }
};

export const getModalStepByURL = (stepId) => {
  const gifMap = {
    1: uzay_temeller_1,
    11: uzay_temeller_2,
    21: uzay_donguler_1,
    404: uzay_donguler_2,
    414: uzay_donguler_3,
    33: uzay_hata_ayiklama,
    
    43: kutup_ic_ice_donguler,
    55: kutup_while_dongusu_1,
    90: kutup_while_dongusu_2,
    70: kutup_if_kosulu,
    80: kutup_if_else,
    100: kutup_hata,

    110: ciftlik_yeni_bloklar,
    120: ciftlik_boolean,
    130: ciftlik_degişkenler,
    146: ciftlik_for_dongusu,
    157: ciftlik_and_or,
    167: ciftlik_fonksiyonlar_1,
    182: ciftlik_karsilastirma,
    192: ciftlik_fonksiyonlar_2,

    204: CodeAli_temeller_1,
    214: CodeAli_temeller_2,
    224: CodeAliDonguler1,
    234: CodeAliDonguler2,
    244: CodeAliWhileDongusu,
    254: CodeAliKosullu_ifadeler_if_else,
  };

  return gifMap[stepId] || uzay_temeller_1; // stepId eşleşmezse varsayılan bir GIF döner.
};
