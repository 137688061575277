import React, { useEffect, useMemo, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../common/Headers/DashboardHeader";
import { studentPages } from "../../../menu";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../../utils/service";
import Page from "../../../layout/Page/Page";
import PracticeDetail from "./PracticeDetail";
import CodeEditor from "./CodeEditor";
import PracticeResult from "./PracticeResult";
import style from "./practice.module.scss";
import {
  useGetSinglePracticeQuery,
  useGetStudentHomeworkQuery,
} from "../../../store/features/apiSlice";
import { useGetStudentPracticesForStudentQuery } from "../admin/talent cup/talentCupApi";
// import { getSelectedPractices } from "../student/talentCup/competitionPracticeFunctions";
import { Spinner } from "react-bootstrap";
import Icon from "../../../components/icon/Icon";
import { nanoid } from "nanoid";
import HomeworkLessons from "../student/common/HomeworkLessons";
import HomeworkGames from "../student/common/HomeworkGames";
import HomeworkPractices from "../student/common/HomeworkPractices";

function indentCode(code) {
  if (!code) return "";

  var lines = code.split("\n");
  lines = lines.map((line) => "    " + line + "\n");
  let user_code = lines.join("");

  return user_code;
}

const PracticeWithoutLearningPath = () => {
  const [competitionPractices, setCompetitionPractices] = useState(
    JSON.parse(localStorage.getItem("competitionPractices")) || []
  );
  const [homeworkPractices, setHomeworkPractices] = useState(
    JSON.parse(localStorage.getItem("homeworkPractices")) || []
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition") || "";
  const homework = urlParams.get("homework") || "";

  const { data, isSuccess } = useGetSinglePracticeQuery(id);

  /* competition content */
  const { data: allCompetitionPractices, isLoading: competitionLoading } =
    useGetStudentPracticesForStudentQuery(competition, { skip: !competition });
  const games = allCompetitionPractices && allCompetitionPractices[0].games;
  const practices =
    allCompetitionPractices && allCompetitionPractices[0].practices;
  const lessons = allCompetitionPractices && allCompetitionPractices[0].lessons;
  const memoizedLessons = useMemo(() => {
    return lessons?.map((lesson) => {
      return lesson.chapters?.map((chapter) =>
        chapter.lessons?.map((l) => ({
          ...l,
          lessonId: lesson.id,
          chapterId: chapter.id,
        }))
      );
    });
  }, [lessons]); // lessons verisi değiştiğinde yeniden hesaplanır

  /* homework content */
  const { data: hwData, isLoading: hwIsLoading } = useGetStudentHomeworkQuery(
    homework,
    { skip: !homework }
  );
  const hwGames = hwData && hwData.content.game_steps;
  const hwPractices = hwData && hwData.content.learning_paths;
  const hwLessons =
    hwData &&
    hwData.content.course_lessons?.filter(
      (item) => item.school_status === 1 || item.school_status === 3
    );

  // useMinimizeAside();
  const [practice, setPractice] = useState(null);
  const [userCode, setUserCode] = useState("");
  const [codeRunResult, setCodeRunResult] = useState(null);
  const [codeEditorHeight, setCodeEditorHeight] = useState(500);

  useEffect(() => {
    if (isSuccess && data) {
      setPractice(data);
      setUserCode(data.template_code || "");
      setCodeRunResult(null);
    }
  }, [isSuccess, data]);

  const handleUserCodeChange = (value) => {
    setUserCode(value);
  };

  const onUserCodeFocus = (event) => {
    setCodeEditorHeight(500);
  };
  async function submitPractice(id, path_id, code) {
    let postData = {
      practice_id: id,
      user_code: indentCode(code),
      run_type: "submit",
      context_type: competition ? "competition" : homework ? "homework" : "",
      context_id: competition || homework,
    };

    var res = await post("practices/run/", postData);

    setCodeRunResult(res.data);
  }

  const sortedPractices =
    JSON.parse(localStorage.getItem("sortedPractices")) || [];
  const currentIndex =
    sortedPractices.length > 0 ? sortedPractices.indexOf(Number(id)) : 0;
  const lastIndex = sortedPractices.length > 0 ? sortedPractices.length - 1 : 0;
  const [currentPracticeIndex, setCurrentPracticeIndex] = useState(
    currentIndex >= 0 ? currentIndex : 0
  );

  // eslint-disable-next-line
  const [nextPracticeId, setNextPracticeId] = useState(null);

  const nextPractice = () => {
    if (sortedPractices.length > 0) {
      setCurrentPracticeIndex(
        currentPracticeIndex === lastIndex ? 0 : currentPracticeIndex + 1
      );
    }
  };
  const previousPractice = () => {
    if (sortedPractices.length > 0) {
      setCurrentPracticeIndex(
        currentPracticeIndex === 0 ? lastIndex : currentPracticeIndex - 1
      );
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        sortedPractices.length > 0 &&
        currentPracticeIndex >= 0 &&
        currentPracticeIndex < sortedPractices.length
      ) {
        setNextPracticeId(sortedPractices[currentPracticeIndex]);
      } else {
        setNextPracticeId(null); // Hatalı durum için varsayılan değer
      }
    }, 5);
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [currentPracticeIndex]);

  useEffect(() => {
    let practiceResult = codeRunResult;

    // Competition için kontrol
    if (practiceResult?.practice_run_result?.successful && competition) {
      const newPractice = { practice: practiceResult.practice_id, competition };

      const isPracticeExists = competitionPractices.some(
        (item) =>
          item.practice === newPractice.practice &&
          item.competition === newPractice.competition
      );

      if (!isPracticeExists) {
        const updatedPractices = [...competitionPractices, newPractice];
        setCompetitionPractices(updatedPractices);
        localStorage.setItem(
          "competitionPractices",
          JSON.stringify(updatedPractices)
        );
      }
    }

    // Homework için kontrol
    if (practiceResult?.practice_run_result?.successful && homework) {
      const newHomework = { practice: practiceResult.practice_id, homework };

      const isHomeworkExists = homeworkPractices.some(
        (item) =>
          item.practice === newHomework.practice &&
          item.homework === newHomework.homework
      );

      if (!isHomeworkExists) {
        const updatedHomeworkPractices = [...homeworkPractices, newHomework];
        setHomeworkPractices(updatedHomeworkPractices);
        localStorage.setItem(
          "homeworkPractices",
          JSON.stringify(updatedHomeworkPractices)
        );
      }
    }
  }, [
    competitionPractices,
    competition,
    homeworkPractices,
    homework,
    codeRunResult,
  ]);

  return (
    <PageWrapper title={studentPages.practice.text}>
      <DashboardHeader />
      <Page className={`${style.pageContainer}`} container="fluid">
        {competition && (
          <div
            className={`${style.practiceHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
            style={{ backgroundColor: "#B9E0F6", height: "50px" }}
          >
            {competitionLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  {memoizedLessons?.flat().map((l) => (
                    <span
                      key={`${l.lessonId}-${l.chapterId}-${l.id}`}
                      className="lesson-item"
                      style={{
                        color: l.is_solved ? "blue" : "black",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() =>
                        navigate(
                          `/lessons/content/${l.id}?competition=${competition}`
                        )
                      }
                    >
                      {l.is_solved ? (
                        <Icon icon={"check"} color={"success"} size="2x" />
                      ) : (
                        "📖"
                      )}
                    </span>
                  ))}
                  <Icon icon={"HdrWeak"} size="small" color="info" />
                  {practices?.map((practice) => {
                    const { id, is_solved } = practice;
                    return (
                      <span
                        key={nanoid()}
                        className="step-item"
                        style={{
                          color: is_solved ? "blue" : "black",
                          cursor: "pointer",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() =>
                          navigate(`/practice/${id}?competition=${competition}`)
                        }
                      >
                        {competitionPractices.some(
                          (practice) =>
                            practice.practice === id &&
                            practice.competition === competition
                        ) || is_solved ? (
                          <Icon icon={"check"} color={"success"} size="2x" />
                        ) : (
                          "❓"
                        )}
                      </span>
                    );
                  })}
                </div>

                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  {games?.map((game) =>
                    game.sections?.map((section) =>
                      section.steps.map((step) => (
                        <span
                          key={nanoid()}
                          className="step-item"
                          style={{
                            // textDecoration: "underline",
                            color: step.is_solved ? "blue" : "black",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                          onClick={() =>
                            navigate(
                              `/games/${game.id}/sections/${section.id}/steps/${step.id}?competition=${competition}`
                            )
                          }
                        >
                          {step.is_solved ? (
                            <Icon icon={"check"} color={"success"} size="2x" />
                          ) : (
                            "⚪"
                          )}
                        </span>
                      ))
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {homework && (
          <div
            className={`${style.practiceHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
            style={{ backgroundColor: "#B9E0F6", height: "50px" }}
          >
            {hwIsLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  <HomeworkLessons hwLessons={hwLessons} homework={homework} />
                  <Icon icon={"HdrWeak"} size="small" color="info" />
                  <HomeworkPractices
                    hwPractices={hwPractices}
                    homework={homework}
                    homeworkPractices={homeworkPractices}
                    isActive={true}
                  />
                </div>

                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  <HomeworkGames hwGames={hwGames} homework={homework} />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="row mb-1">
          <div className={`${style.leftSection} col-xl-4 col-lg-4`}>
            {practice && (
              <PracticeDetail
                title={practice && practice.title}
                explanation={practice.explanation}
              />
            )}
          </div>
          <div className={`${style.rightSection} col-xl-8 col-lg-8`}>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <CodeEditor
                  handleUserCodeChange={handleUserCodeChange}
                  onUserCodeFocus={onUserCodeFocus}
                  userCode={userCode}
                  codeEditorHeight={codeEditorHeight}
                  nextPractice={nextPractice}
                  previousPractice={previousPractice}
                  currentPracticeIndex={null}
                  lastIndex={lastIndex}
                  withoutLearningPath={true}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <PracticeResult
                  submitPractice={submitPractice}
                  practice={practice}
                  userCode={userCode}
                  // path_id={path_id}
                  codeRunResult={codeRunResult}
                />
              </div>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default PracticeWithoutLearningPath;
