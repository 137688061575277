import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import {
  useGameStepWithSectionIdQuery,
  useGameStepsWithSectionQuery,
  useGetStudentHomeworkQuery,
} from "../../../../store/features/apiSlice";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import style from "../Games.module.scss";
import Button from "../../../../components/bootstrap/Button";
import { nextGame, previousGame } from "./setGameStepWithSection";
import { useGetStudentPracticesForStudentQuery } from "../../admin/talent cup/talentCupApi";
import { Spinner } from "react-bootstrap";
import Icon from "../../../../components/icon/Icon";
import HomeworkLessons from "../../student/common/HomeworkLessons";
import HomeworkGames from "../../student/common/HomeworkGames";
import HomeworkPractices from "../../student/common/HomeworkPractices";

const GameStepWithSectionId = ({ isFluid }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [competitionGames, setCompetitionGames] = useState(
    JSON.parse(localStorage.getItem("competitionGames")) || []
  );
  const [homeworkGames, setHomeworkGames] = useState(
    JSON.parse(localStorage.getItem("homeworkGames")) || []
  );
  const urlParams = new URLSearchParams(window.location.search);
  const competition = urlParams.get("competition");
  const homework = urlParams.get("homework");
  const { gameId, sectionId, stepId } = useParams();
  const {
    data: step,
    isLoading: gameLoading,
    refetch: gameRefetch,
  } = useGameStepWithSectionIdQuery(
    {
      gameId,
      sectionId,
      stepId,
    },
    { skip: !stepId || isNaN(stepId) }
  );
  const { data, isLoading, refetch } = useGetStudentPracticesForStudentQuery(
    competition,
    { skip: !competition }
  );
  const games = data && data[0].games;
  const practices = data && data[0].practices;
  const lessons = data && data[0].lessons;

  const { data: hwData, isLoading: hwIsLoading } = useGetStudentHomeworkQuery(
    homework,
    { skip: !homework }
  );
  const hwGames = hwData && hwData.content.game_steps;
  const hwPractices = hwData && hwData.content.learning_paths;
  const hwLessons =
    hwData &&
    hwData.content.course_lessons?.filter(
      (item) => item.school_status === 1 || item.school_status === 3
    );

  let stage = "PROD";
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;
  const isProd = baseUrl.includes("app.talent14");
  if (!isProd) {
    // dev code
    stage = "DEV";
  }
  const userId = localStorage.getItem("id");
  const createMarkup = () => {
    return {
      __html: step
        ? `<iframe src=${step.url}?user=${userId}?game=${
            step.id
          }?stage=${stage}?competition=${competition ?? null}?homework=${
            homework ?? null
          } style="flex-grow: 1; border: none; margin: 0; padding: 0;"></iframe>`
        : "",
    };
  };
  useEffect(() => {
    gameRefetch();
    //eslint-disable-next-line
  }, [location]);
  useEffect(() => {
    if (gameId && sectionId && stepId) {
      console.log(
        `Navigated to game ${gameId}, section ${sectionId}, step ${stepId}`
      );
    }
  }, [gameId, sectionId, stepId]);

  // Oyunlarda sonraki adıma geçme işlevi burada ayarlandı
  const { data: steps } = useGameStepsWithSectionQuery({
    id: gameId,
    sectionId,
  });
  const firstStep = steps && steps.length > 0 ? steps[0].id : null;
  const lastStep =
    steps && steps.length > 0 ? steps[steps.length - 1].id : null;

  const gameStep = step && step;
  function handleNextGame() {
    if (gameStep && gameId && sectionId) {
      nextGame(gameStep, gameId, sectionId, navigate, lastStep);
    } else {
      console.error(
        "Gerekli veriler eksik: gameStep, gameId veya sectionId yok."
      );
    }
  }
  function handlePreviousGame() {
    if (gameStep && gameStep.id !== undefined && gameId && sectionId) {
      previousGame(gameStep, gameId, sectionId, navigate, firstStep);
    } else {
      console.error(
        "Gerekli veriler eksik: gameStep, gameId veya sectionId yok."
      );
    }
  }

  const gameHeaderBackgroundColors = {
    1: "#F4D7A8",
    2: "#74C2EE",
    3: "#B9E0F6",
    4: "#7F553D",
    5: "#74C2EE",
    8: "#4DD0E1",
  };

  const gameHeaderBackgroundColor =
    gameHeaderBackgroundColors[gameId] || "#fff";

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === "https://gamelab.bilisimschool.com") {
        let gameResult = event.data.gameData;

        // Eğer oyun başarıyla tamamlandıysa
        if (gameResult?.status === "success") {
          // Sadece competition varsa işlem yap
          if (competition) {
            refetch();

            const newGame = { game: gameResult.game, competition };

            // Aynı oyunun aynı competition için kaydedilmemesi için kontrol
            const isCompetitionGameExists = competitionGames.some(
              (item) =>
                item.game === newGame.game &&
                item.competition === newGame.competition
            );

            if (!isCompetitionGameExists) {
              const updatedCompetitionGames = [...competitionGames, newGame];
              setCompetitionGames(updatedCompetitionGames);
              localStorage.setItem(
                "competitionGames",
                JSON.stringify(updatedCompetitionGames)
              );
            }
          }

          // Sadece homework varsa işlem yap
          if (homework) {
            const newHomework = { game: gameResult.game, homework };

            // Aynı oyunun aynı homework için kaydedilmemesi için kontrol
            const isHomeworkGameExists = homeworkGames.some(
              (item) =>
                item.game === newHomework.game &&
                item.homework === newHomework.homework
            );

            if (!isHomeworkGameExists) {
              const updatedHomeworkGames = [...homeworkGames, newHomework];
              setHomeworkGames(updatedHomeworkGames);
              localStorage.setItem(
                "homeworkGames",
                JSON.stringify(updatedHomeworkGames)
              );
            }
          }
        }
      }
    };

    window.addEventListener("message", handleMessage);

    // Event listener'ı temizle
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    //eslint-disable-next-line
  }, [competitionGames, competition, homeworkGames, homework]);

  return (
    <PageWrapper title={step && step.title}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        {!competition && !homework ? (
          <>
            {gameLoading ? (
              <div className={style.loadingOverlay}>
                <div className={style.spinner}>
                  <div className={style.ball}></div>
                </div>
                <p className={style.loadingText}>Oyun yükleniyor...</p>
              </div>
            ) : (
              <>
                <div
                  className={`${style.gameHeader} d-flex justify-content-center align-items-center gap-1`}
                  style={{ backgroundColor: `${gameHeaderBackgroundColor}` }}
                >
                  <div>
                    <Button
                      icon="ArrowBackIos"
                      color="warning"
                      isLight
                      onClick={() => handlePreviousGame(gameId)}
                    >
                      önceki
                    </Button>
                  </div>
                  <div>
                    <Button
                      icon="ArrowForwardIos"
                      color="success"
                      isLight
                      onClick={() => handleNextGame(gameId)}
                    >
                      sonraki
                    </Button>
                  </div>
                </div>
                <div
                  style={{ display: "flex", width: "100%", height: "100%" }}
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </>
            )}
          </>
        ) : competition ? (
          <>
            {isLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`${style.gameHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
                  style={{ backgroundColor: `${gameHeaderBackgroundColor}` }}
                >
                  <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                    {lessons?.map((lesson) =>
                      lesson.chapters?.map((chapter) =>
                        chapter.lessons?.map((l) => (
                          <span
                            key={l.id}
                            className="lesson-item"
                            style={{
                              // textDecoration: "underline",
                              color: l.is_solved ? "blue" : "black",
                              cursor: "pointer",
                              fontSize: "16px",
                            }}
                            onClick={() =>
                              navigate(
                                `/lessons/content/${l.id}?competition=${competition}`
                              )
                            }
                          >
                            {l.is_solved ? (
                              <Icon
                                icon={"check"}
                                color={"success"}
                                size="2x"
                              />
                            ) : (
                              "📖"
                            )}
                          </span>
                        ))
                      )
                    )}
                    <Icon icon={"HdrWeak"} size="small" color="info" />
                    {practices?.map((practice) => {
                      const { id, is_solved } = practice;
                      return (
                        <span
                          key={id}
                          className="step-item"
                          style={{
                            color: is_solved ? "blue" : "black",
                            cursor: "pointer",
                            fontSize: "16px",
                            marginRight: "10px",
                          }}
                          onClick={() =>
                            navigate(
                              `/practice/${id}?competition=${competition}`
                            )
                          }
                        >
                          {is_solved ? (
                            <Icon icon={"check"} color={"success"} size="2x" />
                          ) : (
                            "❓"
                          )}
                        </span>
                      );
                    })}
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                      {games?.map((game) =>
                        game.sections?.map((section) =>
                          section.steps.map((step) => (
                            <span
                              key={step.id}
                              className="step-item"
                              style={{
                                // textDecoration: "underline",
                                color: step.is_solved ? "blue" : "black",
                                cursor: "pointer",
                                fontSize: "16px",
                              }}
                              onClick={() =>
                                navigate(
                                  `/games/${game.id}/sections/${section.id}/steps/${step.id}?competition=${competition}`
                                )
                              }
                            >
                              {competitionGames.some(
                                (game) =>
                                  game.game === String(step.id) &&
                                  game.competition === competition
                              ) || step.is_solved ? (
                                <Icon
                                  icon={"check"}
                                  color={"success"}
                                  size="2x"
                                />
                              ) : (
                                "⚪"
                              )}
                            </span>
                          ))
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", width: "100%", height: "100%" }}
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </>
            )}
          </>
        ) : (
          <>
            {hwIsLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`${style.gameHeader} d-flex flex-column justify-content-center align-items-center`}
                  style={{ backgroundColor: `${gameHeaderBackgroundColor}` }}
                >
                  <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                    <HomeworkLessons
                      hwLessons={hwLessons}
                      homework={homework}
                    />
                    <Icon icon={"HdrWeak"} size="small" color="info" />
                    <HomeworkPractices
                      hwPractices={hwPractices}
                      homework={homework}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                    <HomeworkGames
                      hwGames={hwGames}
                      homework={homework}
                      homeworkGames={homeworkGames}
                      isActive={true}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", width: "100%", height: "100%" }}
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </>
            )}
          </>
        )}
      </Page>
    </PageWrapper>
  );
};

export default GameStepWithSectionId;
