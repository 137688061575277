import React, { useEffect, useState } from "react";
import Logo from "../../../../../../components/Logo";
import Icon from "../../../../../../components/icon/Icon";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../../components/bootstrap/Modal";
import { useGetStudentProjectForStudentQuery, useUpdateStudentProjectFileMutation } from "../../../../admin/talent cup/talentCupApi";
import { showError, showSuccess } from "../../../../profilPage/utils";
import { useFormik } from "formik";
import { Button } from "react-bootstrap";
import FormGroup from "../../../../../../components/bootstrap/forms/FormGroup";
import Input from "../../../../../../components/bootstrap/forms/Input";
import Label from "../../../../../../components/bootstrap/forms/Label";
import Spinner from "../../../../../../components/bootstrap/Spinner";
import CustomFormField from "../../../../../../common/customFormField/CustomFormField";

const CompetitionProjectDetailModal = ({
  isOpen,
  setIsOpen,
  competitionId,
}) => {
  const {data:currentProject} = useGetStudentProjectForStudentQuery(competitionId)
  const [sendProject] = useUpdateStudentProjectFileMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (currentProject) {
      formik.setFieldValue("url", currentProject.url);
    }
    //eslint-disable-next-line
  }, [currentProject]);

  const [errorMessage, setErrorMessage] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      formik.setFieldValue("zip_file", selectedFile);
      setErrorMessage(false);
      console.log(selectedFile);
    } else {
      formik.setFieldValue("zip_file", null);
      setErrorMessage(true);
    }
  };

  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$|^(https?:\/\/)?(www\.)?vimeo\.com\/\d+$|^(https?:\/\/)?(www\.)?drive\.google\.com\/(file\/d\/[a-zA-Z0-9_-]+)(\/(view|edit|preview|download))?(\?.*)?$/;



  const validate = (values) => {
    const errors = {};
    if (!values.url) {
      errors.url = "Projenizin youtube linkini giriniz.";
    }
    if (!regex.test(values.url)) {
      errors.url = "Geçerli bir youtube/vimeo/google drive linki giriniz.";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      id: competitionId,
      zip_file: null,
      url: "",
    },
    validate,
    onSubmit: async (values) => {
      console.log(values);
      if (!formik.values.zip_file || !formik.values.id) {
        setErrorMessage(true);
      } else {
        saveCompetition(values);
      }
    },
  });

  const saveCompetition = async (_data) => {
    const data = { ..._data };
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("zip_file", data.zip_file);
      formData.append("url", data.url);
      const response = await sendProject({ id: data.id, formData: formData });
      if (response.error) {
        showError();
      } else {
        showSuccess("Dosya gönderildi");
        setIsOpen(false);
      }
      formik.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      titleId="example-title"
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Görev Detayı</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-12 col-lg-9 mx-auto">
            {currentProject && currentProject.zip_file && <div className="mb-2 text-success"><Icon icon={"check"} size="2x"></Icon>Sistemde kayıtlı proje dosyanız bulunmaktadır.</div>}
            <form onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <Label>Proje Dosyası</Label>
                <FormGroup id="logo">
                  <Input
                    onChange={handleFileChange}
                    type="file"
                    accept=".zip"
                  />
                </FormGroup>
                {errorMessage && (
                  <div>
                    <p
                      style={{
                        color: "#f46639",
                        fontSize: "11px",
                        margin: "3px",
                      }}
                    >
                      Zip Dosyası Ekleyiniz
                    </p>
                  </div>
                )}
              </div>
              <div className="col-12 mt-2">
                <CustomFormField
                  type="text"
                  label="Link"
                  name={"url"}
                  formik={formik}
                  style={{ height: "100px" }}
                />
              </div>
              <div className="mt-2">
                <Button
                  className={`bg-info text-light`}
                  // type="submit"
                  onClick={async () => {
                    formik.handleSubmit();
                  }}
                  isDisable={isSubmitting}
                >
                  {/* Kaydet */}
                  {isSubmitting ? (
                    <Spinner inButton isSmall>
                      Loading...
                    </Spinner>
                  ) : (
                    "Kaydet"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CompetitionProjectDetailModal;
