import React, { useState } from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGamesQuery,
  useGamesWithSectionQuery,
} from "../../../../store/features/apiSlice";
import Button from "../../../../components/bootstrap/Button";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import { getGameSectionStatusColor, getGameSectionStatusText } from "../gameFunctions";
import { getDifficultyText } from "../../../../common/data/enumGameDifficulty";
import SectionModal from "../gameModals/SectionModal";

const GameSections = ({ isFluid }) => {
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const handleCancel = () => setOpen(false);
  const handleSection = (url) => {
    setCurrentUrl(url);
    setOpen(true)
  }
  const handleStartClick = () => {
    setOpen(false)
    // window.location.href = currentUrl; // URL'ye git
    navigate(currentUrl)
  };

  const userSubscriptionType = localStorage.getItem("subscription_type");
  //   const userSubscriptionType = 3;
  const { id } = useParams();
  const { data: sections} = useGamesWithSectionQuery({ id });
  const { data: games} = useGamesQuery();
  let sectionTitle = games && games.filter((game) => Number(game.id) === Number(id));

  const keywords = {
    games: "games",
    codeBoo: "codeBoo",
    codeCizz: "codeCizz",
  };
  const currentKeyword = window.location.pathname.split("/")[1];
  const keywordUrl = keywords[currentKeyword] || "games";

  const navigate = useNavigate();

  const handleNavigation = (path, activeTab) => {
    navigate(`${path}?activeTab=${activeTab}`);
  };
  const excludedSections = [32, 33, 34, 35, 36];
  return (
    <PageWrapper title={sectionTitle && sectionTitle[0]?.title}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
      <SectionModal
        open={open}
        setOpen={setOpen}
        onCancel={handleCancel}
        onClick={handleStartClick}
        currentUrl={currentUrl}
      />
        <div className="row">
          <div className="col-lg-12">
            <div className="card-header px-0 bg-transparent">
              <div className="card-label">
                <div className="card-title-wrapper">
                  <h5 className="card-title">
                    {sectionTitle && sectionTitle[0]?.title}
                  </h5>
                </div>
              </div>
            </div>
            {sections?.map((section) => {
              const { id: sectionId, title, user_status, difficulty } = section;
              const url = `/${keywordUrl}/${id}/sections/${sectionId}/steps`;
              return (
                <div className="card " key={sectionId}>
                  <div className="card-body">
                    <div className="row g-3 align-items-center">
                      <div className="col d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <div
                            className="ratio ratio-1x1"
                            style={{ width: "72px" }}
                          >
                            <div className="rounded-2 d-flex align-items-center justify-content-center bg-l10-info">
                              <img
                                src={sectionTitle && sectionTitle[0]?.image_path}
                                alt="Bank Of America"
                                width={50}
                                height="50px"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                          <div>
                            <div className="fw-bold fs-6 mb-0">{title}</div>
                            <div className="text-muted">
                              <small>
                                Seviye:{" "}
                                <span className="text-normal fw-bold">
                                  {getDifficultyText(difficulty)}
                                </span>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      {Number(id) === 1 || Number(sectionId) === 25 ? (
                        <div className="col-auto">
                          <Button
                            className={`bg-l10-${getGameSectionStatusColor(user_status)} text-${getGameSectionStatusColor(user_status)} fw-bold px-3 py-2 rounded-pill}`}
                            size="lg"
                            tag="a"
                            // to={url}
                            onClick={() => handleSection(url)} 
                          >
                            {getGameSectionStatusText(user_status)}
                          </Button>
                        </div>
                      ) : (!excludedSections.includes(Number(sectionId))) &&
                        Number(userSubscriptionType) ===
                          USER_SUBSCRIPTION_TYPE.STANDART.id ? (
                        <div className="col-auto">
                          <Button
                            className={`bg-l10-${getGameSectionStatusColor(user_status)} text-${getGameSectionStatusColor(user_status)} fw-bold px-3 py-2 rounded-pill}`}
                            size="lg"
                            tag="a"
                            // to={url}
                            onClick={() => handleSection(url)}
                          >
                            {getGameSectionStatusText(user_status)}
                          </Button>
                        </div>
                      ) : Number(userSubscriptionType) !==
                        USER_SUBSCRIPTION_TYPE.PREMIUM.id ? (
                        <div className="col-auto">
                          <Button
                            className={`bg-danger text-white fw-bold px-3 py-2 rounded-pill}`}
                            size="lg"
                            tag="a"
                            onClick={() =>
                              handleNavigation(`/profile`, "Account Details")
                            }
                          >
                            Planı Yükselt
                          </Button>
                        </div>
                      ) : (
                        <div className="col-auto">
                          <Button
                            className={`bg-l10-${getGameSectionStatusColor(user_status)} text-${getGameSectionStatusColor(user_status)} fw-bold px-3 py-2 rounded-pill}`}
                            size="lg"
                            tag="a"
                            // to={url}
                            onClick={() => handleSection(url)}
                          >
                            {getGameSectionStatusText(user_status)}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default GameSections;
