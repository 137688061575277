import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Page from "../../../../layout/Page/Page";
import {
  useGetStudentHomeworkQuery,
  useSingleCourseLessonQuery,
} from "../../../../store/features/apiSlice";
import style from "./SingleCourseLessonPage.module.scss";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import LectureContentVideo from "./LectureContentVideo";
import { Spinner } from "react-bootstrap";
import { useGetStudentPracticesForStudentQuery } from "../../admin/talent cup/talentCupApi";
import { useCompetition } from "../../admin/talent cup/CompetitionContext";
import { useHomework } from "../../admin/talent cup/HomeworkContext";
import Icon from "../../../../components/icon/Icon";
import HomeworkLessons from "../../student/common/HomeworkLessons";
import HomeworkGames from "../../student/common/HomeworkGames";
import HomeworkPractices from "../../student/common/HomeworkPractices";

export function SingleCourseLessonPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: lesson, isLoading } = useSingleCourseLessonQuery(id, {
    skip: id === null,
  });
  const { competitionLessons } = useCompetition();
  const { homeworkLessons } = useHomework();
  useEffect(() => {
    console.log("Competition lessons updated:", competitionLessons);
  }, [competitionLessons]);
  useEffect(() => {
    console.log("Homework lessons updated:", homeworkLessons);
  }, [homeworkLessons]);

  const [searchParams] = useSearchParams();
  const competition = searchParams.get("competition") || "";
  const homework = searchParams.get("homework") || "";

  /* competition content */
  const { data: allCompetitionPractices, isLoading: competitionLoading } =
    useGetStudentPracticesForStudentQuery(competition, { skip: !competition });
  const games = allCompetitionPractices && allCompetitionPractices[0].games;
  const practices =
    allCompetitionPractices && allCompetitionPractices[0].practices;
  const lessons = allCompetitionPractices && allCompetitionPractices[0].lessons;

  /* homework content */
  const { data: hwData, isLoading: hwIsLoading } = useGetStudentHomeworkQuery(
    homework,
    { skip: !homework }
  );
  const hwGames = hwData && hwData.content.game_steps;
  const hwPractices = hwData && hwData.content.learning_paths;
  const hwLessons =
    hwData &&
    hwData.content.course_lessons?.filter(
      (item) => item.school_status === 1 || item.school_status === 3
    );
  return (
    <PageWrapper>
      <DashboardHeader />
      <Page container="fluid">
        {competition && (
          <div
            className={`${style.practiceHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
            style={{ backgroundColor: "#B9E0F6", height: "50px" }}
          >
            {competitionLoading || isLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  {lessons?.map((lesson) =>
                    lesson.chapters?.map((chapter) =>
                      chapter.lessons?.map((l) => (
                        <span
                          key={l.id}
                          className="lesson-item"
                          style={{
                            // textDecoration: "underline",
                            color: l.is_solved ? "blue" : "black",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                          onClick={() =>
                            navigate(
                              `/lessons/content/${l.id}?competition=${competition}`
                            )
                          }
                        >
                          {competitionLessons?.some(
                            (lesson) =>
                              lesson.lesson === Number(l.id) &&
                              lesson.competition === competition
                          ) || l.is_solved ? (
                            <Icon icon={"check"} color={"success"} size="2x" />
                          ) : (
                            "📖"
                          )}
                        </span>
                      ))
                    )
                  )}
                  <Icon icon={"HdrWeak"} size="small" color="info" />
                  {practices?.map((practice) => {
                    const { id, is_solved } = practice;
                    return (
                      <span
                        key={id}
                        className="step-item"
                        style={{
                          color: is_solved ? "blue" : "black",
                          cursor: "pointer",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                        onClick={() =>
                          navigate(`/practice/${id}?competition=${competition}`)
                        }
                      >
                        {is_solved ? (
                          <Icon icon={"check"} color={"success"} size="2x" />
                        ) : (
                          "❓"
                        )}
                      </span>
                    );
                  })}
                </div>

                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  {games?.map((game) =>
                    game.sections?.map((section) =>
                      section.steps.map((step) => (
                        <span
                          key={step.id}
                          className="step-item"
                          style={{
                            // textDecoration: "underline",
                            color: step.is_solved ? "blue" : "black",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                          onClick={() =>
                            navigate(
                              `/games/${game.id}/sections/${section.id}/steps/${step.id}?competition=${competition}`
                            )
                          }
                        >
                          {step.is_solved ? (
                            <Icon icon={"check"} color={"success"} size="2x" />
                          ) : (
                            "⚪"
                          )}
                        </span>
                      ))
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {homework && (
          <div
            className={`${style.practiceHeader} d-flex justify-content-center align-items-center gap-1 flex-wrap`}
            style={{ backgroundColor: "#B9E0F6", height: "50px" }}
          >
            {hwIsLoading || isLoading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "3rem", height: "3rem", color: "#007bff" }}
                  >
                    <span className="sr-only">...</span>
                  </Spinner>
                  <h3
                    className="mt-3"
                    style={{ color: "#007bff", fontWeight: "bold" }}
                  >
                    Yükleniyor...
                  </h3>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  <HomeworkLessons
                    hwLessons={hwLessons}
                    homework={homework}
                    homeworkLessons={homeworkLessons}
                    isActive={true}
                  />
                  <Icon icon={"HdrWeak"} size="small" color="info" />
                  <HomeworkPractices
                    hwPractices={hwPractices}
                    homework={homework}
                  />
                </div>

                <div className="d-flex justify-content-center align-items-center gap-1 flex-wrap">
                  <HomeworkGames hwGames={hwGames} homework={homework} />
                </div>
              </div>
            )}
          </div>
        )}
        <div className={`${style.videoScreen} col-11 px-3 mx-auto`}>
          <Card className={`${style.videoScreenCard} h-100`}>
            <CardBody className="pb-0">
              <LectureContentVideo lesson={lesson} />
            </CardBody>
          </Card>
        </div>
      </Page>
    </PageWrapper>
  );
}
