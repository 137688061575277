import React, { useState } from "react";
import style from "./CouponList.module.scss";
import plus from "../../../../../assets/dashboardImages/plus.svg";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../../components/PaginationButtons";
import { useNavigate } from "react-router";
import useSortableData from "../../../../../hooks/useSortableData";
import _ from "underscore";
import { Card, Spinner } from "react-bootstrap";
import { CardBody } from "../../../../../components/bootstrap/Card";
import { IconButton, Tooltip } from "@mui/material";
import { Delete, Edit } from "../../../../../components/icon/material-icons";
import ShowCouponModal from "./ShowCouponModal";
import { useDeleteDiscountMutation, useDiscountsQuery } from "../../../../../store/features/couponApi";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";
import Confirm from "../../../../common/Confirm";
import { getCustomErrorMessage, handleErrorMessage } from "../../../../../utils/customErrors";
// import { Delete } from "@mui/icons-material";

const CouponList = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useDiscountsQuery();
  const [deleteCoupon] = useDeleteDiscountMutation();

  let sortedData = _.sortBy(
    data,
    (item) => item.expiry_date
  ).reverse();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["8"]);
  const { items } = useSortableData(sortedData ? sortedData : []);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [open, setOpen] = useState(false);

  const showCouponDetail = (id) => {
    navigate(`/admin/discountCoupon/${id}`);
  };
  const handleCoupon = (e) => {
    setIsOpenModal(true);
    setCouponId(e.currentTarget.id && e.currentTarget.id);
  };

    //! delete couponId------
    const removeCoupon = (e) => {
      setOpen(true);
      setCouponId(e.currentTarget.id && e.currentTarget.id);
    };
    const handleDelete = async () => {
      const response = await deleteCoupon(couponId);
      setOpen(false);
      if (response.error) {
        const customError = handleErrorMessage(response.error.data);
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="danger" size="lg" className="me-1" />
            <span>{getCustomErrorMessage(customError)}</span>
          </span>,
          "Bir hata oluştu",
          "danger"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Info" size="lg" className="me-1" />
            <span>Silme Başarılı</span>
          </span>,
          "Kupon başarıyla silindi.",
          "success"
        );
      }
    };
    const handleCancel = () => setOpen(false);
    //!-----------------------------------------------
  return (
    <>
      <ShowCouponModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        couponId={couponId}
      />
       <Confirm
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <div className="d-flex justify-content-end mx-5">
        <div
          className={`${style.addCompetitionContainer} d-flex align-items-center gap-2 ps-2`}
          onClick={(e) => handleCoupon(e)}
        >
          <span>
            <img src={plus} alt="" />
          </span>
          <span>İndirim Kuponu Ekle</span>
        </div>
      </div>
      <Card className={`${style.cardContainer} w-100`}>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <CardBody>
            <div className={`${style.customTable} mt-5 px-4`}>
              <div
                className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
              >
                <div
                  className={`${style.customTableHead1} ${style.customTableHead}`}
                >
                  Başlık
                </div>
                <div
                  className={`${style.customTableHead2} ${style.customTableHead}`}
                >
                  Kupon
                </div>

                <div
                  className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                >
                  İ.Oranı
                </div>
                <div
                  className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                >
                  İ.Miktarı
                </div>
                <div
                  className={`${style.customTableHead5} ${style.customTableHead} text-center`}
                >
                  Kullanılan
                </div>
                <div
                  className={`${style.customTableHead6} ${style.customTableHead} text-center`}
                >
                  Başlangıç
                </div>
                <div
                  className={`${style.customTableHead7} ${style.customTableHead} text-center`}
                >
                  Bitiş
                </div>
                <div
                  className={`${style.customTableHead8} ${style.customTableHead} text-center`}
                >
                  Eylemler
                </div>
              </div>
              <div className={`${style.customRowContainer}`}>
                {items.length === 0 ? (
                  <div>
                    <span>Kayıtlı öğrenci bulunamadı</span>
                  </div>
                ) : (
                  dataPagination(items, currentPage, perPage).map(
                    (coupon, i) => {
                      const {
                        id,
                        usage_student_count,
                        title,
                        code,
                        discount_percentage,
                        discount_amount,
                        start_date,
                        expiry_date,
                      } = coupon;
                      return (
                        <div
                          key={id}
                          className={`${style.customTableRow} ${
                            i % 2 === 0 && style.customBackgroundColor
                          } d-flex align-items-center mt-1 px-2`}
                        >
                          <div className={`${style.customTableColumn1} cursor-pointer`} onClick={()=>showCouponDetail(id)}>
                            <div>
                              <div className={`${style.titleContainer}`}>
                                <div className={`${style.titleShort}`}>
                                  {title}
                                </div>
                                <div className={`${style.titleFull}`}>
                                  {title}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={`${style.customTableColumn2}`}>
                            <div className={`${style.descriptionContainer}`}>
                              <div className={`${style.descriptionShort}`}>
                                {code}
                              </div>
                              <div className={`${style.descriptionFull}`}>
                                {code}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${style.customTableColumn3} text-center`}
                          >
                            <div>{discount_percentage ?? "-"}</div>
                          </div>
                          <div
                            className={`${style.customTableColumn4} text-center`}
                          >
                            <div>{discount_amount ?? "-"}</div>
                          </div>
                          <div
                            className={`${style.customTableColumn5} text-center`}
                          >
                            {usage_student_count ?? "-"}
                          </div>
                          <div
                            className={`${style.customTableColumn6} text-center`}
                          >
                            {start_date}
                          </div>
                          <div
                            className={`${style.customTableColumn7} text-center`}
                          >
                            {expiry_date}
                          </div>
                          <div
                            className={`${style.customTableColumn8} d-flex justify-content-center mx-auto`}
                          >
                            <div className="d-flex justify-content-center gap-2">
                              <IconButton
                                id={id} onClick={(e) => removeCoupon(e)}
                              >
                                <Delete className="fs-3" />
                              </IconButton>

                              <Tooltip title="Edit">
                                <IconButton
                                  id={id} onClick={(e) => handleCoupon(e)}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                )}
              </div>
            </div>
            <PaginationButtons
              data={items}
              label="kullanıcı"
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default CouponList;
