// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.StepGifModal_imageContainer__Qnp7T {\n  position: relative;\n  width: 100%; /* Genişlik konteynerin genişliğine uyacak şekilde ayarlanır */\n  height: 0;\n  padding-top: 50%; /* 16:9 en-boy oranı için */\n  overflow: hidden;\n}\n\n.StepGifModal_responsiveImage__dgu2P {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  -o-object-fit: cover;\n     object-fit: cover; /* Resmi kapsayıcıya göre kırpmak için */\n}", "",{"version":3,"sources":["webpack://./src/pages/t14/games/gameModals/StepGifModal.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,kBAAA;EACA,WAAA,EAAA,8DAAA;EACA,SAAA;EACA,gBAAA,EAAA,2BAAA;EACA,gBAAA;AAEJ;;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;KAAA,iBAAA,EAAA,wCAAA;AAEJ","sourcesContent":[".imageContainer {\n    position: relative;\n    width: 100%; /* Genişlik konteynerin genişliğine uyacak şekilde ayarlanır */\n    height: 0;\n    padding-top: 50%; /* 16:9 en-boy oranı için */\n    overflow: hidden;\n  }\n  \n  .responsiveImage {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* Resmi kapsayıcıya göre kırpmak için */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": "StepGifModal_imageContainer__Qnp7T",
	"responsiveImage": "StepGifModal_responsiveImage__dgu2P"
};
export default ___CSS_LOADER_EXPORT___;
