// import icerikler from '../../assets/menuImages/CodeHub.png';
// import codelab from '../../assets/menuImages/CodeLab.png';
// import codemino from '../../assets/menuImages/CodeMino.png';
// import codeboo from '../../assets/menuImages/CodeBoo.png';
// import codecizz from '../../assets/menuImages/CodeÇizz.png';
// import talentcup from '../../assets/menuImages/TalentCup.png';
// import codeart from '../../assets/menuImages/CodeArt.png';
import robot14 from '../../assets/menuImages/robot14.webp';
import icerikler from '../../assets/menuImages/CodeHub2.webp';
import codelab from '../../assets/menuImages/CodeLab2.webp';
import codemino from '../../assets/menuImages/CodeMino2.webp';
import codeboo from '../../assets/menuImages/CodeBoo3.webp';
import codecizz from '../../assets/menuImages/CodeÇizz2.webp';
import talentcup from '../../assets/menuImages/TalentCup2.webp';

export const studentMenuImages = {
    icerikler:icerikler,
    codelab:codelab,
    codemino:codemino,
    codeboo:codeboo,
    codecizz:codecizz,
    // codeart:codeart,
    talentcup:talentcup,
    robot14:robot14,
}
