import React, { useState } from "react";
import style from "./CompetitionDetailForStudent.module.scss";
import Button from "../../../../../../components/bootstrap/Button";
import Page from "../../../../../../layout/Page/Page";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "../../../../../../layout/PageWrapper/PageWrapper";
import SubHeader, {
  SubHeaderLeft,
} from "../../../../../../layout/SubHeader/SubHeader";
import { formatDateTime } from "../../../../../../utils/formatDate";
import { getCompetitionKind } from "../competition_card/CompetitionCard";
import { COMPETITION_KIND } from "../../../../../../common/data/enumCompetition";
import {
  useGet_a_Competition_ThumbnailQuery,
  useGet_a_CompetitionQuery,
  useJoinCompetitionMutation,
} from "../../../../admin/talent cup/talentCupApi";
import { showError, showSuccess } from "../../../../profilPage/utils";
import {
  getCustomErrorMessage,
  handleErrorMessage,
} from "../../../../../../utils/customErrors";
import CompetitionPracticeDetailModal from "./CompetitionPracticeDetailModal";
import CompetitionProjectDetailModal from "./CompetitionProjectDetailModal";
import TalentResponsiveImage from "../../../../admin/talent cup/components/TalentResponsiveImage";
import TalentDescription from "../../../../admin/talent cup/components/TalentDescription";
import TalentInfoStudent from "../../../../admin/talent cup/components/TalentInfoStudent";
import { Spinner } from "react-bootstrap";
import TalentStudentSkor from "../../../../admin/talent cup/components/TalentStudentSkor";

const CompetitionDetailForStudent = () => {
  const [isOpen, setIsOpen] = useState(false);
  let { id: competitionId } = useParams();
  const {
    data: logoUrl,
    isLoading,
  } = useGet_a_Competition_ThumbnailQuery(competitionId, {
    skip: !competitionId,
  });
  const {
    data: competition,
    isLoading: competitionLoading,
    refetch,
  } = useGet_a_CompetitionQuery(competitionId, { skip: !competitionId });
  const isProjectCompetition =
    Number(competition?.kind) === COMPETITION_KIND.PROJECT;

  const today = new Date();
  const endDate = new Date(competition?.end_date);
  const startDate = new Date(competition?.start_date);
  const [joinCompetition] = useJoinCompetitionMutation();

  const handleCompetition = async () => {
    const response = await joinCompetition(competitionId);
    if (response.error) {
      const customError = handleErrorMessage(response.error.data);
      console.log(customError);
      showError(getCustomErrorMessage(customError));
    } else {
      showSuccess("Başvuru başarılı");
      refetch();
    }
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/talentCup");
  };

  if (competitionLoading || isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f8f9fa' }}>
        <div className="text-center">
          <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem', color: '#007bff' }}>
            <span className="sr-only">Loading...</span>
          </Spinner>
          <h3 className="mt-3" style={{ color: '#007bff', fontWeight: 'bold' }}>Loading...</h3>
        </div>
      </div>
    );
  }
  return (
    <PageWrapper title={"Yarışma Bilgileri"}>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="info"
            isLink
            icon="ArrowBack"
            tag="a"
            onClick={goBack}
            // className={style.SubHeader}
          >
            Yarışmalar Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
      </SubHeader>
      <Page>
      {(competition?.is_student_joined && !isProjectCompetition) &&  <CompetitionPracticeDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          competitionId={competitionId}
        />}
      {(competition?.is_student_joined && isProjectCompetition) &&  <CompetitionProjectDetailModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          competitionId={competitionId}
        />}
        <div className="card h-100 py-5 px-5">
          <div className="row">
            <div className="col-12 col-lg-9 mx-auto">
              <TalentResponsiveImage src={logoUrl} />
            </div>
            <div className={`${style.talentActionsButton} col-12 col-lg-9 mx-auto`}>
              {endDate > today ? (
                <Button
                  className={`${style.joinButton} my-2`}
                  style={{
                    backgroundColor: `${
                      competition?.is_student_joined ? "6c5dd3" : "#4D69FA"
                    }`,
                  }}
                  onClick={() => !(competition?.is_student_joined) ? handleCompetition() : setIsOpen(true)}
                  disabled={
                    today < startDate
                      ? true
                      : false
                  }
                >
                  {(competition?.is_student_joined) ? "Görevi Göster" : (isProjectCompetition && competition?.is_student_joined) ? "Görevi Göster" : today < startDate ? "Yakında" : "Katıl"}
                </Button>
              ) : (
                <Button
                  className={`${style.joinButton} my-2 bg-danger`}
                  disabled
                >
                  Sona Erdi
                </Button>
              )}
            </div>
            <div className={`${style.talentInfo} col-12 col-lg-9 mt-2 mx-auto`}>
              <TalentInfoStudent
                startDate={formatDateTime(competition?.start_date)}
                endDate={formatDateTime(competition?.end_date)}
                type={getCompetitionKind(competition?.kind)}
                score={competition?.points || 0}
              />
            </div>
          { competition?.is_student_joined && <div className="col-12">
            <TalentStudentSkor studentScore={competition?.student_competition_score || 0}/>
            </div>}
            <div className={`${style.talendDesc} col-12 col-lg-9 mx-auto`}>
              <TalentDescription content={competition?.description} />
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default CompetitionDetailForStudent;
