import React from "react";
import style from "./StudentWithClass.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import classLogo from "../../../../../../src/assets/dashboardImages/classLogo.png";
import studentLogo from "../../../../../../src/assets/dashboardImages/studentLogo.svg";
import SchoolInfo from "./2-ClassInfo";
import StudentInfo from "./3-StudentInfo";
import ActiveHomeworks from "./4-ActiveHomeworks";
import {
  useGetListStudentsHomeworkQuery,
  useGetSchoolBannerForAdminQuery,
  useGetStudentSubscribedClassesQuery,
} from "../../../../../store/features/apiSlice";
import _ from "underscore";
import { useGet_a_SchoolQuery } from "../../../teacher/teacherApi";
import Banner from "../banner and announcements/banner/Banner";

const StudentWithClass = () => {
  const school = localStorage.getItem("school");
  const { data: banner, isLoading: isLoadingBanner } =
    useGetSchoolBannerForAdminQuery(school, { skip: !school });

  const { data } = useGetStudentSubscribedClassesQuery();
  const { data: studentHomeworkList } = useGetListStudentsHomeworkQuery();
  const { data: schoolData } = useGet_a_SchoolQuery(school, { skip: !school });
  const studentClass = 3;
  const studentClassName = data && data.class_name;
  const teacherFulName = data && data.teacher_name;
  const rankInClass = data && data.rank_in_the_class;
  const allStudentsInClass =
    data && data.the_total_number_of_students_in_the_class;
  const homeworkCompletionRate = data && data.homework_completion_rate;
  const schoolName = schoolData && schoolData.title;

  let allHomeworks = studentHomeworkList && studentHomeworkList;
  allHomeworks = _.sortBy(allHomeworks, "start_date").reverse();
  return (
    <div>
      <SchoolInfo
        studentClass={studentClass}
        studentClassName={studentClassName}
        teacherFulName={teacherFulName}
        schoolName={schoolName}
      />
      {!isLoadingBanner && <Card>{banner && <Banner banner={banner} />}</Card>}
      <Card>
        <div className={`${style.studentWithClassTopSection}`}>
          <div className={`${style.leftSection}`}>
            <div className={`${style.leftSectionFirst}`}>
              <StudentInfo
                logo={studentLogo}
                description={"Sınıf Başarı Sırası"}
                detail={rankInClass + "/" + allStudentsInClass}
              />
            </div>
            <div className={`${style.leftSectionSecond}`}>
              <StudentInfo
                logo={classLogo}
                description={"Ödev Tamamlama Oranı"}
                detail={"%" + homeworkCompletionRate}
              />
            </div>
          </div>
          <div className={`${style.rightSection}`}>
            <ActiveHomeworks homeworks={allHomeworks} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default StudentWithClass;
