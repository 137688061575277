import React, { useState } from "react";
import style from "./CouponDetail.module.scss";
import PaginationButtons, {
  dataPagination,
  PER_COUNT,
} from "../../../../../components/PaginationButtons";
import { useParams } from "react-router";
import { Card, Spinner } from "react-bootstrap";
import { CardBody } from "../../../../../components/bootstrap/Card";
import { useSingleDiscountQuery } from "../../../../../store/features/couponApi";
import SubHeader, { SubHeaderLeft } from "../../../../../layout/SubHeader/SubHeader";
import Button from "../../../../../components/bootstrap/Button";

const CouponDetail = () => {
  const { id } = useParams();
  const {
    data: coupon,
    isLoading,
  } = useSingleDiscountQuery(id, { skip: !id });
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["8"]);

  if (isLoading) {
    return <div className="mx-auto"><Spinner/></div>;
  }
  return (
    <>
      <Card className={`${style.cardContainer} w-100`}>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="info"
            isLink
            icon="ArrowBack"
            tag="a"
            to={`/admin/discountCoupon`}
            // className={style.SubHeader}
          >
            İndirim Kuponları Sayfasına Geri Dön
          </Button>
        </SubHeaderLeft>
        {/* <SubHeaderRight></SubHeaderRight> */}
      </SubHeader>
        {/* Kupon Bilgileri */}
        <div className={`${style.topContainer} mt-5`}>
          <div className={style.couponInfo}>
            <h3 className={style.couponTitle}>{coupon.title}</h3>
            <div className={style.infoRow}>
              <span className={style.infoLabel}>Kod:</span>
              <span className={style.infoValue}>{coupon.code}</span>
            </div>
            <div className={style.infoRow}>
              <span className={style.infoLabel}>İndirim Miktarı:</span>
              <span className={style.infoValue}>
              {coupon.discount_percentage !== null
              ? `${coupon.discount_percentage}%`
              : `${coupon.discount_amount}₺`}
              </span>
            </div>
            <div className={style.infoRow}>
              <span className={style.infoLabel}>Başlangıç Tarihi:</span>
              <span className={style.infoValue}>{coupon.start_date}</span>
            </div>
            <div className={style.infoRow}>
              <span className={style.infoLabel}>Bitiş Tarihi:</span>
              <span className={style.infoValue}>{coupon.expiry_date}</span>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <CardBody>
            <div className={`${style.customTable} mt-5 px-4`}>
              <div
                className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
              >
                <div
                  className={`${style.customTableHead1} ${style.customTableHead}`}
                >
                  Email
                </div>
                <div
                  className={`${style.customTableHead2} ${style.customTableHead}`}
                >
                  Ad Soyad
                </div>

                <div
                  className={`${style.customTableHead3} ${style.customTableHead} text-center`}
                >
                  Abonelik Türü
                </div>
                <div
                  className={`${style.customTableHead4} ${style.customTableHead} text-center`}
                >
                  Abonelik Süresi
                </div>
              </div>
              <div className={`${style.customRowContainer}`}>
                {coupon.usage_student_count === 0 ? (
                  <div>
                    <span>Kayıtlı öğrenci bulunamadı</span>
                  </div>
                ) : (
                  dataPagination(
                    coupon.usage_students,
                    currentPage,
                    perPage
                  ).map((student, i) => {
                    const { email, full_name } = student;
                    return (
                      <div
                        key={id}
                        className={`${style.customTableRow} ${
                          i % 2 === 0 && style.customBackgroundColor
                        } d-flex align-items-center mt-1 px-2`}
                      >
                        <div className={`${style.customTableColumn1}`}>
                          <div>
                            <div className={`${style.titleContainer}`}>
                              <div className={`${style.titleShort}`}>
                                {email}
                              </div>
                              <div className={`${style.titleFull}`}>
                                {email}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`${style.customTableColumn2}`}>
                          <div className={`${style.descriptionContainer}`}>
                            <div className={`${style.descriptionShort}`}>
                              {full_name}
                            </div>
                            <div className={`${style.descriptionFull}`}>
                              {full_name}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${style.customTableColumn3} text-center`}
                        >
                          <div>
                            {student.subscription_type === 1
                              ? "Standart"
                              : "Premium"}
                          </div>
                        </div>
                        <div
                          className={`${style.customTableColumn4} text-center`}
                        >
                          <div>
                            {student.subscription_period === 0
                              ? "Aylık"
                              : student.subscription_period === 1
                              ? "Yıllık"
                              : "Bilinmiyor"}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <PaginationButtons
              data={coupon.usage_students}
              label="kullanıcı"
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default CouponDetail;
