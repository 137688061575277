import React from "react";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../components/bootstrap/Modal";
import style from "./StepGifModal.module.scss"

import { getModalStepByURL } from "./gameModalFunctions";

const StepGifModal = ({ open, setOpen, currentUrl, onCancel }) => {
  const modalContent = getModalStepByURL(currentUrl);

  return (
    <div className={`${style.stegGifModal}`}>
      <Modal
        isOpen={open}
        setIsOpen={setOpen}
        isCentered
        titleId="competition-info-title"
        size="xl"
      >
        <ModalHeader>
          <button
            onClick={onCancel}
            style={{
              background: "none",
              border: "none",
              fontSize: "20px",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
              transform: "scale(1.5)",
            }}
          >
            &times; {/* X işareti */}
          </button>
        </ModalHeader>
        <ModalBody>
          <div className={`${style.imageContainer}`}>
            <img className={`${style.responsiveImage}`} src={modalContent} alt={"gif"} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default StepGifModal;
