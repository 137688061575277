import React from "react";
import style from "./LastDays.module.scss";
import Card from "../../../../../components/bootstrap/Card";
import SingleDay from "./2-SingleDay";
import { useUserActivitiesInLastDaysQuery } from "../../../../../store/features/apiSlice";

const LastDays = () => {
  const { data } = useUserActivitiesInLastDaysQuery();

  const last_10_days_activities = React.useMemo(() => {
    return data ? Object.entries(data).reverse() : [];
  }, [data]);
  const formatDate = (date) =>
    `${date.slice(8, 10)}-${date.slice(5, 7)}-${date.slice(0, 4)}`;

  return (
    <div className={`${style.lastDaysContainer}`}>
      <Card className={`${style.cardDiv}`}>
        <div
          className={`${style.headerDiv} d-flex justify-content-center align-items-start flex-column ps-5`}
        >
          <span>Son 10 günde yaptıklarım</span>
        </div>
        <div className={`${style.daysContainer} my-2`}>
          {last_10_days_activities?.map(([description, date]) => (
            <SingleDay
              key={description}
              description={description}
              date={formatDate(date)}
            />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default LastDays;
