import React from "react";
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from "react-dom/client"; // For React 18
import { BrowserRouter as Router } from "react-router-dom";
import "swiper/css/bundle";
import "./styles/styles.scss";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./contexts/themeContext";
//import "./i18n";
import { AuthProvider } from "./store/auth-store";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

const RELOAD_TIMEOUT = 2 * 60 * 1000; // 2 dakika (ms)
const RELOAD_KEY = "hasReloaded";

// Sayfa yüklendiğinde, zaman aşımını kontrol et
function checkReloadTimeout() {
  const reloadData = JSON.parse(localStorage.getItem(RELOAD_KEY));
  if (reloadData && Date.now() - reloadData.timestamp > RELOAD_TIMEOUT) {
    localStorage.removeItem(RELOAD_KEY);
  }
}

// Hata sonrası yeniden yükleme işlemi
function handleGlobalError() {
  const reloadData = JSON.parse(localStorage.getItem(RELOAD_KEY));
  if (!reloadData) {
    console.error("Hata yakalandı, sayfa yeniden yükleniyor...");
    localStorage.setItem(
      RELOAD_KEY,
      JSON.stringify({ timestamp: Date.now() })
    );
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } else {
    console.warn("Sayfa zaten yakın zamanda yeniden yüklendi.");
  }
}

// Sayfa yüklendiğinde zaman aşımını kontrol et
checkReloadTimeout();

// Global hata dinleyicisi
window.onerror = function (message, source, lineno, colno, error) {
  console.error("Global error caught:", { message, source, lineno, colno, error });
  handleGlobalError();
};

window.addEventListener("unhandledrejection", (event) => {
  console.error("Unhandled promise rejection:", event.reason);
  handleGlobalError();
});



const children = (
  <Router>
    <ThemeContextProvider>
      <AuthProvider>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App /> 
          </PersistGate>
        </Provider>
      </AuthProvider>
    </ThemeContextProvider>
  </Router>
);

const container = document.getElementById("root");

createRoot(container).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
