import React, { useEffect, useState } from "react";
import _ from "underscore";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import Button from "../../../../../components/bootstrap/Button";
import Select from "../../../../../components/bootstrap/forms/Select";
import { useCoursesQuery } from "../../../../../store/features/apiSlice";
import {
  useCreateTeacherTrailMutation,
  useGetActiveClassesQuery,
  useTeacherSingleTrailQuery,
  useUpdateTeacherTrailMutation,
} from "../../teacherApi";
import Option from "../../../../../components/bootstrap/Option";
import Label from "../../../../../components/bootstrap/forms/Label";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import showNotification from "../../../../../components/extras/showNotification";
import CustomFormField from "../../../../../common/customFormField/CustomFormField";

const CreateTrailModal = ({ isOpenNewPathModal, setIsOpenNewPathModal }) => {
  const schoolId = localStorage.getItem("school");
  const isSchoolId = schoolId > 0;
  //states
  const [selectedLessons, setSelectedLessons] = useState([]);
  const selectedPathId = useSelector((status) => status.teacher.selectedPathId);

  // mutations and queries
  const [createPathMutation] = useCreateTeacherTrailMutation();
  const [updatePathMutation] = useUpdateTeacherTrailMutation();
  const { data } = useCoursesQuery();
  const { data: classList, isSuccess: isSuccessClasses } =
    useGetActiveClassesQuery();
  const { data: singlePathData, isSuccess: singlePathSuccess } =
    useTeacherSingleTrailQuery(selectedPathId, {
      skip: selectedPathId === undefined,
    });
  // constants
  let activeLessons = data?.filter((item) => item.status === 1);
  activeLessons = _.sortBy(activeLessons, "order_number");
  let lessons = activeLessons;

  //useEffects
  useEffect(() => {
    if (singlePathSuccess) {
      formik.values.id = singlePathData.id;
      formik.values.title = singlePathData.title;
      formik.values.description = singlePathData.description;
      formik.values.order_number = singlePathData.order_number;
      formik.values.status = singlePathData.status;
      formik.values.classroom = singlePathData.classroom.id;
      setSelectedLessons(singlePathData.courses.map((item) => item.id));
    }
    //eslint-disable-next-line
  }, [singlePathSuccess]);

  useEffect(() => {
    formik.setFieldValue("courses", selectedLessons);
    //eslint-disable-next-line
  }, [selectedLessons]);

  useEffect(() => {
    if (isSuccessClasses) {
      formik.setFieldValue("classroom", classList[0]?.id);
    }
    //eslint-disable-next-line
  }, [isSuccessClasses]);

  //functions
  const handleLesson = (lessonId) => {
    if (selectedLessons.includes(lessonId)) {
      setSelectedLessons(selectedLessons.filter((id) => id !== lessonId));
    } else {
      setSelectedLessons([...selectedLessons, lessonId]);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Dersin adını giriniz";
    }
    if (!values.description) {
      errors.description = "Açıklama giriniz";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      id: "0",
      title: "",
      description: "",
      order_number: 1,
      status: 1,
      courses: selectedLessons,
      classroom: 0,
      school: isSchoolId ? schoolId : null,
      school_status: isSchoolId ? 2 : 1,
    },
    validate,
    onSubmit: (values) => {
      // console.log("values:", values)
      if (values.id > 0) updatePath();
      else createPath();
    },
  });

  const updatePath = async () => {
    const pathValues = { ...formik.values };
    const response = await updatePathMutation({
      data: pathValues,
      pathId: pathValues.id,
    });
    if (response.error) {
      showError();
    } else {
      showSuccess("Patika başarıyla güncellendi");
    }
    setIsOpenNewPathModal(false);
    setSelectedLessons([]);
    formik.resetForm();
  };
  const createPath = async () => {
    const pathValues = { ...formik.values };
    const response = await createPathMutation(pathValues);
    if (response.error) {
      showError();
    } else {
      showSuccess("Patika başarıyla eklendi.");
    }
    setIsOpenNewPathModal(false);
    setSelectedLessons([]);
    formik.resetForm();
  };

  function showError() {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="danger" size="lg" className="me-1" />
        <span>Kayıt Başarısız</span>
      </span>,
      "Bir hata oluştu",
      "danger"
    );
  }

  function showSuccess(message) {
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="Info" size="lg" className="me-1" />
        <span>Kayıt Başarılı</span>
      </span>,
      message,
      "success"
    );
  }

  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenNewPathModal}
      setIsOpen={setIsOpenNewPathModal}
      titleId="example-title"
      size={null}
    >
      <ModalHeader setIsOpen={setIsOpenNewPathModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Yeni Patika</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-8">
            <CustomFormField
              type="text"
              label="Başlık"
              name={"title"}
              formik={formik}
            />
          </div>
          <div className="col-lg-8">
            <CustomFormField
              type="textArea"
              label="Açıklama"
              name={"description"}
              formik={formik}
              style={{ height: "150px" }}
            />
          </div>
          <div
            className="col-11 col-lg-8 pt-2 rounded-2"
            style={{ backgroundColor: "#F8F9FA" }}
          >
            <span className="h5">Ders Başlığı</span>
            <div
              className="mt-2"
              style={{ maxHeight: "200px", overflow: "scroll" }}
            >
              {lessons?.map((lesson) => (
                <FormGroup key={lesson.id}>
                  <Label className="d-flex">
                    <input
                      type="checkbox"
                      checked={selectedLessons?.includes(lesson.id)}
                      onChange={() => handleLesson(lesson.id)}
                      name="selectedLessons"
                      value={lesson.id}
                    />
                    <span className="mx-3">{lesson.title}</span>
                  </Label>
                </FormGroup>
              ))}
            </div>
          </div>
          <div className="col-lg-8">
            <CustomFormField
              type="text"
              label="Sıra Numarası"
              name={"order_number"}
              formik={formik}
            />
          </div>
          <div className="col-lg-8">
            <FormGroup id="classroom" label="Sınıf" isFloating>
              <Select
                ariaLabel="Select"
                value={
                  formik.values.classroom
                    ? String(formik.values.classroom)
                    : "0"
                }
                style={{ width: "100%" }}
                onChange={(e) => {
                  formik.setFieldValue("classroom", e.target.value);
                }}
              >
                {classList && classList.length > 0 ? (
                  classList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))
                ) : (
                  <Option value="0">No Classes Available</Option>
                )}
              </Select>
            </FormGroup>
          </div>
          <div className="col-lg-8">
            <CustomFormField
              type="select"
              label="Aktiflik"
              name={"status"}
              options={[
                { value: "0", text: "Pasif" },
                { value: "1", text: "Aktif" },
                { value: "2", text: "Taslak" },
              ]}
              formik={formik}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          icon="DoneOutline"
          color="success"
          isLight
          onClick={async () => {
            formik.handleSubmit();
          }}
        >
          Kaydet
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateTrailModal;
