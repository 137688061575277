import React, { useEffect, useState } from "react";
import style from "./UserListTableForSchool.module.scss";
import PaginationButtons, {
  PER_COUNT,
} from "../../../../../components/PaginationButtons";
import useSortableData from "../../../../../hooks/useSortableData";
import USER_ROLES from "../../../../../common/data/enumUserRole";
import { Edit, SettingsAccessibility, VpnKey } from "@mui/icons-material";
import Icon from "../../../../../components/icon/Icon";
import UpdatePasswordWithAdminModal from "../../../admin/user management/6-UpdatePasswordWithAdminModal";
import UpdateUserModalForSchoolManager from "./UpdateUserModalForSchoolManager";
import { useNavigate } from "react-router-dom";
import {
  useListAllClassOfTeacherQuery,
  useUsersQuery,
} from "../../../../../store/features/apiSlice";
import { useMediaQuery } from "react-responsive";
import { downloadExcel } from "../../../../../common/downloadExcel";
import Button from "../../../../../components/bootstrap/Button";
import { useSchoolUsersQuery } from "../../schoolManagerApi";

const UserListTableForSchool = ({ usersType }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);

  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
  const isSchoolManager = Number(role) === USER_ROLES.SCHOOL_MANAGER.id;
  const schoolId = localStorage.getItem("schoolId");

  //todo teachers
  const schoolTeachersForAdmin = `school=${schoolId}&role=2&page=${currentPage}&page_size=${perPage}`;
  const schoolTeachersForManager = `role=2&page=${currentPage}&page_size=${perPage}`;
  const { data: teachersForAdmin } = useUsersQuery(schoolTeachersForAdmin, {
    skip: !isAdmin,
  });
  const { data: teachersForManager } = useSchoolUsersQuery(
    schoolTeachersForManager,
    { skip: !isSchoolManager }
  );

  //todo students
  const schoolStudentsForAdmin = `school=${schoolId}&role=1&page=${currentPage}&page_size=${perPage}`;
  const schoolStudentsForManager = `role=1&page=${currentPage}&page_size=${perPage}`;
  const { data: studentsForAdmin } = useUsersQuery(schoolStudentsForAdmin, {
    skip: !isAdmin,
    refetchOnMountOrArgChange: false,
  });
  const { data: studentsForManager } = useSchoolUsersQuery(
    schoolStudentsForManager,
    { skip: !isSchoolManager, refetchOnMountOrArgChange: false }
  );

  const [teacherId, setTeacherId] = useState(null);
  const [userId, setUserId] = useState(null);
  const isStudentType = usersType === "student";
  const allUsers =
    isStudentType && isAdmin
      ? studentsForAdmin
      : isStudentType && !isAdmin
      ? studentsForManager
      : !isStudentType && isAdmin
      ? teachersForAdmin
      : teachersForManager;
  const users = allUsers?.results;
  const totalUsers = allUsers?.count;
  // const isStudentType = Number(role) === USER_ROLES.STUDENT.id;
  const isMobile = useMediaQuery({ minWidth: 768 });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenUpdatePasswordModal, setIsOpenUpdatePasswordModal] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { items, requestSort, getClassNamesFor } = useSortableData(
    filteredUsers ? filteredUsers : users ? users : []
  );
  const { data: teacherClasses } = useListAllClassOfTeacherQuery(teacherId, {
    skip: teacherId === null,
  });

  useEffect(() => {
    filterUsers();
    //eslint-disable-next-line
  }, [searchValue]);

  const filterUsers = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const newUsers =
      users &&
      users.filter(
        (item) => item.email && item.email.toLowerCase().includes(searchValue)
      );
    setFilteredUsers(newUsers);
  };

  useEffect(() => {
    setSearchValue("");
    filterUsers();
    //eslint-disable-next-line
  }, [users]);

  const showReport = (publicId, role, id) => {
    if (role === USER_ROLES.STUDENT.id) {
      navigate(`/users/information/${publicId}`);
    } else {
      setTeacherId(id);
    }
  };

  useEffect(() => {
    if (teacherClasses) {
      localStorage.setItem("teacherClasses", JSON.stringify(teacherClasses));
      navigate("/teacherReport");
    }
    //eslint-disable-next-line
  }, [teacherId, teacherClasses]);

  const openModal = (e) => {
    setIsOpenModal(true);
    setUserId(e.currentTarget.id && e.currentTarget.id);
  };

  const handlePassword = (e) => {
    setIsOpenUpdatePasswordModal(true);
    setUserId(e.currentTarget.id && e.currentTarget.id);
  };

  const handleReport = () => {
    const data = items;
    const headers = [
      { key: "username", label: "Kullanıcı Adı" },
      { key: "email", label: "E-posta" },
      {
        key: `${isStudentType ? "parent_telephone_number" : "role"}`,
        label: `${isStudentType ? "Veli Telefon Numarası" : "Profil tipi"}`,
        transform: (value) => {
          if (Number(value) === USER_ROLES.TEACHER.id) {
            return "Öğretmen";
          }
        },
      },
    ];
    const fileName = "kullanici_listesi.xlsx";
    downloadExcel(data, headers, fileName);
  };
  return (
    <div>
      <UpdateUserModalForSchoolManager
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        userId={userId}
      />
      <UpdatePasswordWithAdminModal
        isOpenUpdatePasswordModal={isOpenUpdatePasswordModal}
        setIsOpenUpdatePasswordModal={setIsOpenUpdatePasswordModal}
        userId={userId}
      />
      <Button
        className={`${style.messageButton} btn`}
        color="primary"
        onClick={() => handleReport()}
      >
        Excel olarak indir
      </Button>
      <div className={`${style.actionsContainer} px-2 mx-4 gap-3 mx-auto`}>
        <div className={`${style.inputContainer} `}>
          <div
            className={`${style.searchContainer} d-flex align-items-center justify-content-center`}
          >
            <form className="d-flex gap-1 w-100" onSubmit={filterUsers}>
              <label className="" htmlFor="searchInput">
                <Icon
                  icon="Search"
                  size="2x"
                  style={{ color: "#000", opacity: "0.3" }}
                />
              </label>
              <input
                className="form-control border-0 shadow-none bg-transparent"
                type="search"
                placeholder={
                  isMobile && "Aramak istediğiniz kullanıcıyı yazınız"
                }
                // placeholder="Aramak istediğiniz kullanıcıyı yazınız"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.toLowerCase())}
              />
            </form>
          </div>
        </div>
      </div>

      <div className={`${style.customTable} mt-5 px-2`}>
        <div
          className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
        >
          <div
            className={`${style.customTableHead1} ${style.customTableHead}`}
            onClick={() => requestSort("username")}
          >
            Kullanıcı adı
            <Icon
              size="lg"
              className={getClassNamesFor("username")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead2} ${style.customTableHead}`}
            onClick={() => requestSort("email")}
          >
            E-posta
            <Icon
              size="lg"
              className={getClassNamesFor("email")}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            onClick={() =>
              requestSort(
                `${isStudentType ? "parent_telephone_number" : "role"}`
              )
            }
          >
            {isStudentType ? "Veli Telefon No" : "Profil tipi"}
            <Icon
              size="lg"
              className={getClassNamesFor(
                `${isStudentType ? "parent_telephone_number" : "role"}`
              )}
              icon="FilterList"
            />
          </div>
          <div
            className={`${style.customTableHead4} ${style.customTableHead} text-center`}
          >
            Eylemler
          </div>
        </div>
        <div className={`${style.customRowContainer}`}>
          {users && items.length === 0 ? (
            <div>
              <span>Kayıtlı kullanıcı bulunamadı</span>
            </div>
          ) : (
            // dataPagination(items, currentPage, perPage).map((item, i) => (
            (filteredUsers ? filteredUsers : users)?.map((item, i) => (
              <div
                key={item.id}
                className={`${style.customTableRow} ${
                  i % 2 === 0 && style.customBackgroundColor
                } d-flex align-items-center mt-1 px-2`}
              >
                <div className={`${style.customTableColumn1} cursor-pointer`}>
                  <div className={`${style.titleContainer}`}>
                    <div className={`${style.titleShort}`}>
                      {item.username ? item.username : item.email.split("@")[0]}
                    </div>
                    <div className={`${style.titleFull}`}>
                      {item.username ? item.username : item.email.split("@")[0]}
                    </div>
                  </div>
                </div>
                <div className={`${style.customTableColumn2}`}>
                  <div className="d-flex">
                    <div className={`${style.schoolContainer}`}>
                      <div
                        className={`${style.schoolShort}`}
                      >{`${item.email}`}</div>
                      <div
                        className={`${style.schoolFull}`}
                      >{`${item.email}`}</div>
                    </div>
                  </div>
                </div>
                <div className={`${style.customTableColumn3} text-center`}>
                  <div>
                    {Number(item.role) === USER_ROLES.STUDENT.id
                      ? item.parent_telephone_number || "-"
                      : Number(item.role) === USER_ROLES.TEACHER.id
                      ? "Öğretmen"
                      : "-"}
                  </div>
                </div>

                {/* Eylemler */}
                <div
                  className={`${style.customTableColumn4} d-flex justify-content-center mx-auto`}
                >
                  <div className="d-flex justify-content-center gap-2">
                    <VpnKey
                      className={`${style.icon} cursor-pointer`}
                      onClick={(e) => handlePassword(e)}
                      id={item.id}
                    />
                    <Edit
                      className={`${style.icon} cursor-pointer`}
                      onClick={(e) => openModal(e)}
                      id={item.id}
                    />
                    <SettingsAccessibility
                      className={`${style.icon} cursor-pointer`}
                      onClick={() =>
                        showReport(item.public_id, item.role, item.id)
                      }
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <PaginationButtons
        data={totalUsers}
        label="kullanıcı"
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={true}
      />
    </div>
  );
};

export default UserListTableForSchool;
