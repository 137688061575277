import React, { useEffect, useState } from "react";
import style from "./SchoolManagerHeader.module.scss";
import defaultSchool from "../../../../../assets/school/defaultSchoolLogo.png";
import bannerSchool1 from "../../../../../assets/dashboardImages/bannerSchool1.png";
import USER_ROLES from "../../../../../common/data/enumUserRole";
import Spinner from "../../../../../components/bootstrap/Spinner";

const SchoolManagerHeader = ({ schoolName = "TEST OKULLARI", }) => {
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem("roles");
  const isAdmin = Number(role) === USER_ROLES.ADMIN.id;
    const schoolId = isAdmin ? localStorage.getItem("schoolId") : localStorage.getItem("school")
    const spinner = (
      <Spinner color="primary" style={{ marginTop: "20px" }} />
    )

  const [schoolLogo, setSchoolLogo] = useState(null);

 useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      try {
        const logoUrl = await getSchoolLogo(schoolId);
        if (isMounted) {
          setSchoolLogo(logoUrl || null);
        }
      } catch (error) {
        console.error("Error fetching school logo:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    }
    fetchData();
    return () => {
      isMounted = false;
    };
  }, [schoolId]);
  return (
    <div className={`${style.schoolContainer} d-flex align-items-center`}>
      <img className={`${style.banner}`} src={bannerSchool1} alt="" />
     { loading ? spinner: <div
        className={`${style.leftSection}`}
      >
        <div
          className={`${style.schoolLogo}`}
        >
          {!schoolLogo ? (
            <img src={defaultSchool} alt="" />
          ) : (
            <img
              src={schoolLogo}
              alt=""
              width={64}
              height={64}
              className="mx-auto d-block"
            />
          )}
        </div>
      </div>}
    </div>
  );
};

export default SchoolManagerHeader;


export async function getSchoolLogo(schoolId) {
  const baseUrl = process.env.REACT_APP_CENTRAL_API_URL;
  function getToken() {
    let token = localStorage.getItem("token");
    if (token) {
      return JSON.parse(token);
    }
  }
  const token = getToken();

  try {
    const res = await fetch(baseUrl + `schools/${schoolId}/logo/png/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.ok) {
      // console.log("res",res.body);
      const blob = await res.blob();
      return URL.createObjectURL(blob);
    } else {
      throw new Error("Failed to fetch logo");
    }
  } catch (error) {
    console.error("Error fetching logo:", error);
    return null;
  }
}
