import React from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  // AccordionSummary,
  // Divider,
  Typography,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.css"

const Item = styled(Typography)`
color:#098919
`;
const Fail = styled(Typography)`
color:#b80d28
`;

function TestResult({ test }) {
  test.successful ? (
    <Item>{"Test #" + test.test_id}</Item>
  ) : (
    <Fail>{"Test #" + test.test_id}</Fail>
  );

  return (
        <Accordion>
          <AccordionDetails>
            <div className="test-params">
            Program Çıktısı: <span className="actual"> {test.test_output || "YOK"}</span>
            </div>
          </AccordionDetails>
        </Accordion>
  );
}

export default TestResult;
