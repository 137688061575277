import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeader from "../../../common/Headers/DashboardHeader";

const Robot14 = () => {
  function createMarkup() {
    return {
      __html:
        '<iframe src="https://gamelab.bilisimschool.com/robot14" style="flex-grow: 1; border: none; margin: 0; padding: 0;"></iframe>',
    };
  }
  return (
    <PageWrapper title={"Robot14"}>
      <DashboardHeader />
      <Page container={false} style={{ paddingTop: "0" }}>
        <div
          style={{ display: "flex", width: "100%", height: "100%" }}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </Page>
    </PageWrapper>
  );
};

export default Robot14;
