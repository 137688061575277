import React from "react";
import style from "./RewardLigCard.module.scss";
import BronzeCard from "../../../../../../assets/badges/BronzeCard.png";
import BronzeAvatar from "../../../../../../assets/badges/BronzeAvatar.png";
import SilverCard from "../../../../../../assets/badges/SilverCard.png";
import SilverAvatar from "../../../../../../assets/badges/SilverAvatar.png";
import GoldCard from "../../../../../../assets/badges/GoldCard.png";
import GoldAvatar from "../../../../../../assets/badges/GoldAvatar.png";
import AVATARS from "../../../../../../common/data/avatarsDummy";

  const RewardLigCard = React.memo(({ lig, activeLig }) => {
  const userAvatar = localStorage.getItem("user_avatar");
  const rewardLigCards = {
    bronze: {
      card: BronzeCard,
      avatar: BronzeAvatar,
      title: "BRONZ LİG",
      description: "Bronz Lig'e",
      amount: 4,
      color: "#7F4A34",
    },
    silver: {
      card: SilverCard,
      avatar: SilverAvatar,
      title: "GÜMÜŞ LİG",
      description: "Gümüş Lig'e",
      amount: 8,
      color: "#68686D",
    },
    gold: {
      card: GoldCard,
      avatar: GoldAvatar,
      title: "ALTIN LİG",
      description: "Altın Lig'e",
      amount: 12,
      color: "#E69D31",
    },
  };
  const { card, avatar, title, color, amount, description } =
    rewardLigCards[lig];
  return (
    <div
      className={`${style.rewardCardContainer} d-flex align-items-center justify-content-center gap-5 mx-auto pe-5`}
      style={{ opacity:`${!(lig === activeLig) ? "0.3" : "1"}` }}
    >
      <img className={`${style.rewardCardBanner}`} src={card} alt="" />
      <div className={`d-${!(lig === activeLig) && "none"}`}>
      <div className={`${style.activeCardTopLeft}`} style={{ borderColor:`${color}` }}></div>
      <div className={`${style.activeCardTopRight}`} style={{ borderColor:`${color}` }}></div>
      <div className={`${style.activeCardBottomLeft}`} style={{ borderColor:`${color}` }}></div>
      <div className={`${style.activeCardBottomRight}`} style={{ borderColor:`${color}` }}></div>
      </div>
      <div className={`${style.rewardAvatarContainer}`}>
        <img className={`${style.rewardAvatar}`} src={avatar} alt="" />
        <div
          className={`${style.userAvatarDiv} d-flex justify-content-center mx-auto`}
        >
          <img
            src={
              userAvatar && userAvatar !== "null"
                ? userAvatar
                : AVATARS.defaultAvatarSmall
            }
            alt=""
          />
        </div>
      </div>
      <div className={`${style.rewardCardDefinition}`}>
        <p className={`${style.title}`} style={{ color: `${color}` }}>
          {title}
        </p>
        <p className={`${style.description}`}>
          {amount} Adet rozet kazanarak <br />{" "}
          <span style={{ color: `${color}` }}>{description}</span> çıkın.
        </p>
      </div>
    </div>
  );
});

export default RewardLigCard;
