import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import Icon from "../../../../components/icon/Icon";

const HomeworkGames = ({
  hwGames,
  homework,
  isActive = false,
  homeworkGames = [],
}) => {
  const navigate = useNavigate();
  // Veriyi useMemo ile optimize etme
  const memoizedHwGames = useMemo(() => {
    return hwGames?.map((game) => ({
      ...game,
      sections: game.sections?.map((section) => ({
        ...section,
        steps: section.steps.map((step) => ({
          ...step,
          isSolvedOrInHomework:
            homeworkGames.some(
              (homeworkGame) =>
                homeworkGame.game === String(step.id) &&
                homeworkGame.homework === homework
            ) || step.is_solved,
        })),
      })),
    }));
  }, [hwGames, homework, homeworkGames]); // homeworkGames değiştiğinde yeniden hesaplanacak
  return (
    <div>
      {memoizedHwGames?.map((game) => (
        <div key={game.id} className="game d-flex">
          {game.sections?.map((section) => (
            <div key={section.id} className="section">
              {section.steps?.map((step) => (
                <span
                  key={step.id} // Benzersiz key
                  className="step-item"
                  style={{
                    color: step.is_solved ? "blue" : "black",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={() =>
                    navigate(
                      `/games/${game.id}/sections/${section.id}/steps/${step.id}?homework=${homework}`
                    )
                  }
                >
                  {!isActive ? (
                    <>
                      {step.isSolvedOrInHomework ? (
                        <Icon icon={"check"} color={"success"} size="2x" />
                      ) : (
                        "⚪"
                      )}
                    </>
                  ) : (
                    <>
                      {homeworkGames.some(
                        (game) =>
                          game.game === String(step.id) &&
                          game.homework === homework
                      ) || step.is_solved ? (
                        <Icon icon={"check"} color={"success"} size="2x" />
                      ) : (
                        "⚪"
                      )}
                    </>
                  )}
                </span>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default HomeworkGames;
