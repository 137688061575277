import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import Icon from "../../../../components/icon/Icon";

const HomeworkLessons = ({
  hwLessons,
  homework,
  isActive = false,
  homeworkLessons=[],
}) => {
  const navigate = useNavigate();
  // Veriyi useMemo ile optimize etme
  const memoizedHwLessons = useMemo(() => {
    return hwLessons?.map((lesson) =>
      lesson.chapters?.map((chapter) =>
        chapter.lessons?.map((l) => ({
          ...l,
          lessonId: lesson.id,
          chapterId: chapter.id,
        }))
      )
    );
  }, [hwLessons]); // hwLessons verisi değiştiğinde yeniden hesaplanacak

  return (
    <div>
      {memoizedHwLessons?.map((lesson) =>
        lesson?.map((chapter) =>
          chapter?.map((l) => (
            <span
              key={`${l.lessonId}-${l.chapterId}-${l.id}`} // Benzersiz key
              className="lesson-item"
              style={{
                color: l.is_solved ? "blue" : "black",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() =>
                navigate(`/lessons/content/${l.id}?homework=${homework}`)
              }
            >
              {!isActive ? (
                <>
                  {l.is_solved ? (
                    <Icon icon={"check"} color={"success"} size="2x" />
                  ) : (
                    "📖"
                  )}
                </>
              ) : (
                <>
                  {homeworkLessons?.some(
                    (lesson) =>
                      lesson.lesson === Number(l.id) &&
                      lesson.homework === homework
                  ) || l.is_solved ? (
                    <Icon icon={"check"} color={"success"} size="2x" />
                  ) : (
                    "📖"
                  )}
                </>
              )}
            </span>
          ))
        )
      )}
    </div>
  );
};

export default HomeworkLessons;
