import React, { useEffect, useState } from "react";
import {
  useAdminDashboardQuery,
  useListAllClassOfTeacherQuery,
  useUsersQuery,
  useUsersSearchQuery,
} from "../../../../store/features/apiSlice";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import USER_SUBSCRIPTION_TYPE from "../../../../common/data/enumUserSubscriptionType";
import USER_ROLES from "../../../../common/data/enumUserRole";
import Button from "../../../../components/bootstrap/Button";
import CreateUserModal from "./4-CreateUserModal";
import UpdateUserModal from "./3-UpdateUserModal";
import { Edit, SettingsAccessibility, VpnKey } from "@mui/icons-material";
import UpdatePasswordWithAdminModal from "./6-UpdatePasswordWithAdminModal";
import UserBulkAddModal from "./5-UserBulkAddModal";
import { useNavigate } from "react-router-dom";
import { useGetSchoolsQuery } from "../../teacher/teacherApi";
import style from "./UserManagement.module.scss";
import PaginationButtons, {
  PER_COUNT,
} from "../../../../components/PaginationButtons";
import { debounce } from 'lodash';

const UserListTable = () => {
  const navigate = useNavigate();
  const [teacherId, setTeacherId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [search, setSearch] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const handleSearchChange = debounce((value) => {
    setDebouncedSearchTerm(value);
  }, 500);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
    handleSearchChange(e.target.value);
  };
 const params = `page=${currentPage}&page_size=${perPage}`
  const { data, isLoading } = useUsersQuery(params, {refetchOnMountOrArgChange: false});
  const searhParams = `search=${debouncedSearchTerm}&page=${currentPage}&page_size=${perPage}`
  const { data:searchData, isLoading:searchDataIsLoading } = useUsersSearchQuery(searhParams, {skip:!debouncedSearchTerm, refetchOnMountOrArgChange: false});
  const totalUsers = data && data.count
  const totalSearchUsers = searchData && searchData.count
  const { data: teacherClasses } = useListAllClassOfTeacherQuery(teacherId, {
    skip: teacherId === null,
  });
  const { data: schools } = useGetSchoolsQuery();

  const { data:userStatus} = useAdminDashboardQuery();
  const [userDetails, setUserDetails] = useState({
    total: null,
    premium: null,
    standart: null,
    free: null,
    teacher: null,
    student: null,
    school_manager: null,
  });
  useEffect(() => {
    if (userStatus && typeof userStatus === 'object') {
      setUserDetails({
        total: userStatus.number_of_the_users || 0,
        premium: userStatus.number_of_the_premium_users || 0,
        standart: userStatus.number_of_the_standart_users || 0,
        free: userStatus.number_of_the_free_users || 0,
        teacher: userStatus.number_of_the_teachers || 0,
        student: userStatus.number_of_the_students || 0,
        school_manager: userStatus.number_of_the_school_manager || 0,
      });
    } else {
      setUserDetails({
        total: 0,
        premium: 0,
        standart: 0,
        free: 0,
        teacher: 0,
        student: 0,
        school_manager: 0,
      });
    }
  }, [userStatus]);

  const showReport = (publicId, role, id) => {
    if (role === USER_ROLES.STUDENT.id) {
      navigate(`/users/information/${publicId}`);
    } else {
      setTeacherId(id);
    }
  };

  useEffect(() => {
    if (teacherClasses) {
      localStorage.setItem("teacherClasses", JSON.stringify(teacherClasses));
      navigate("/teacherReport");
    }
    //eslint-disable-next-line
  }, [teacherId, teacherClasses]);

  const items = searchData ? searchData?.results : data?.results ?? []
 
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenNewUserModal, setIsOpenNewUserModal] = useState(false);
  const [isOpenBulkUserModal, setIsOpenBulkUserModal] = useState(false);
  const [isOpenUpdatePasswordModal, setIsOpenUpdatePasswordModal] =
    useState(false);
  const [userId, setUserId] = useState(null);

  const addUser = () => {
    setIsOpenNewUserModal(true);
  };
  const openModal = (e) => {
    setIsOpenModal(true);
    setUserId(e.currentTarget.id && e.currentTarget.id);
  };
  const handlePassword = (e) => {
    setIsOpenUpdatePasswordModal(true);
    setUserId(e.currentTarget.id && e.currentTarget.id);
  };
  const handleBulkUser = () => {
    setIsOpenBulkUserModal(true);
  };

  return (
    <Card className={`${style.cardContainer}`}>
      <>
        <UpdateUserModal
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          userId={userId}
          schools={schools}
          />
        <CreateUserModal
          isOpenNewUserModal={isOpenNewUserModal}
          setIsOpenNewUserModal={setIsOpenNewUserModal}
          schools={schools}
        />
        <UserBulkAddModal
          isOpenBulkUserModal={isOpenBulkUserModal}
          setIsOpenBulkUserModal={setIsOpenBulkUserModal}
        />
        <UpdatePasswordWithAdminModal
          isOpenUpdatePasswordModal={isOpenUpdatePasswordModal}
          setIsOpenUpdatePasswordModal={setIsOpenUpdatePasswordModal}
          userId={userId}
        />
      </>
      <div
        className="d-flex justify-content-between p-5 pt-5"
        // onClick={(e) => filterProperties(e)}
      >
        <div className="row">
          <div className="col-auto">
            <Button
              className={`bg-l50-success text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Toplam :{" "}
              <span className="fw-normal fs-3">{`${userDetails.total}`}</span>
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className={`bg-l10-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Premium :{" "}
              <span className="fw-normal fs-3">{`${userDetails.premium}`}</span>
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className={`bg-l10-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Standart :{" "}
              <span className="fw-normal fs-3">{`${userDetails.standart}`}</span>
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className={`bg-l10-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Ücretsiz :{" "}
              <span className="fw-normal fs-3">{`${userDetails.free}`}</span>
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <Button
              className={`bg-l50-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Yönetici :{" "}
              <span className="fw-normal fs-3">{`${userDetails.school_manager}`}</span>
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className={`bg-l50-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Öğretmen :{" "}
              <span className="fw-normal fs-3">{`${userDetails.teacher}`}</span>
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className={`bg-l50-info text-info fw-bold fs-5 px-3 py-2 rounded-pill}`}
            >
              Öğrenci :{" "}
              <span className="fw-normal fs-3">{`${userDetails.student}`}</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="d-flex">
            <Button
              color="info"
              className={`ms-5 mb-4 shadow-3d-up-hover}`}
              onClick={() => addUser()}
            >
              Yeni Kullanıcı Ekle
            </Button>
            <Button
              color="info"
              className={`ms-5 mb-4 shadow-3d-up-hover}`}
              onClick={() => handleBulkUser()}
            >
              Toplu Kullanıcı Ekle
            </Button>
          </div>
        </div>
        <div className="col-6">
          <form className="d-flex gap-1" 
          // onSubmit={filterUsers}
          >
            <input
              className="form-control"
              type="search"
              placeholder="Aramak istediğiniz kullanıcıyı yazınız"
              value={search}
              onChange={handleInputChange}
            />
            <button className="btn btn-warning w-25">Bul</button>
          </form>
        </div>
      </div>
      <CardBody>
        <div className={`${style.customTable} mt-5 px-4`}>
          <div
            className={`${style.customHeadContainer} d-flex align-items-center my-3 px-2`}
          >
            <div
              className={`${style.customTableHead1} ${style.customTableHead}`}
            >
              Kullanıcı Adı
            </div>
            <div
              className={`${style.customTableHead2} ${style.customTableHead}`}
            >
              Email
            </div>

            <div
              className={`${style.customTableHead3} ${style.customTableHead} text-center`}
            >
              Profil Tipi
            </div>
            <div
              className={`${style.customTableHead4} ${style.customTableHead} text-center`}
            >
              Üyelik Tipi
            </div>
            <div
              className={`${style.customTableHead5} ${style.customTableHead} text-start`}
            >
              Okul
            </div>
            <div
              className={`${style.customTableHead6} ${style.customTableHead} text-center`}
            >
              Eylemler
            </div>
          </div>
        {(isLoading || searchDataIsLoading) ? <div>Loading</div> :  <div className={`${style.customRowContainer}`}>
            {items.length === 0 ? (
              <div>
                <span>Kayıtlı öğrenci bulunamadı</span>
              </div>
            ) : 
            (
                items?.map((user, i) => {
                const {
                  id,
                  username,
                  email,
                  role,
                  subscription_type,
                  public_id,
                  school_title,
                } = user;
                return (
                  <div
                    key={id}
                    className={`${style.customTableRow} ${
                      i % 2 === 0 && style.customBackgroundColor
                    } d-flex align-items-center mt-1 px-2`}
                  >
                    <div className={`${style.customTableColumn1}`}>
                      <div>
                        <div className={`${style.titleContainer}`}>
                          <div className={`${style.titleShort}`}>
                            {username ? username : email.split("@")[0]}
                          </div>
                          <div className={`${style.titleFull}`}>
                            {username ? username : email.split("@")[0]}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${style.customTableColumn2}`}>
                      <div className={`${style.descriptionContainer}`}>
                        <div className={`${style.descriptionShort}`}>
                          {email}
                        </div>
                        <div className={`${style.descriptionFull}`}>
                          {email}
                        </div>
                      </div>
                    </div>
                    <div className={`${style.customTableColumn3} text-center`}>
                      <div>
                        {Number(role) === USER_ROLES.STUDENT.id
                          ? "Öğrenci"
                          : Number(role) === USER_ROLES.TEACHER.id
                          ? "Öğretmen"
                          : Number(role) === USER_ROLES.SCHOOL_MANAGER.id
                          ? "Yönetici"
                          : "-"}
                      </div>
                    </div>
                    <div className={`${style.customTableColumn4} text-center`}>
                      {Number(subscription_type) ===
                      USER_SUBSCRIPTION_TYPE.FREE.id
                        ? "Ücretsiz"
                        : Number(subscription_type) ===
                          USER_SUBSCRIPTION_TYPE.STANDART.id
                        ? "Standart"
                        : "Premium"}
                    </div>
                   
                    <div className={`${style.customTableColumn5}`}>
                      <div className={`${style.schoolContainer}`}>
                        <div className={`${style.schoolShort}`}>
                          {school_title}
                        </div>
                        <div className={`${style.schoolFull}`}>
                          {school_title}
                        </div>
                      </div>
                    </div>
                    {/* Eylemler */}
                    <div className={`${style.customTableColumn6} text-center`}>
                      <div
                        className={`${style.actionIcons} d-flex justify-content-center mx-auto`}
                      >
                        <VpnKey
                          className="cursor-pointer"
                          onClick={(e) => handlePassword(e)}
                          id={id}
                        />
                        <Edit
                          className="cursor-pointer"
                          onClick={(e) => openModal(e)}
                          id={id}
                        />
                        <SettingsAccessibility
                          className="cursor-pointer"
                          onClick={() => showReport(public_id, role, id)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>}
        </div>
        <PaginationButtons
          data={debouncedSearchTerm ? totalSearchUsers : totalUsers}
          label="kullanıcı"
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          perPage={perPage}
          setPerPage={setPerPage}
          total={true}
        />
      </CardBody>
    </Card>
  );
};

export default UserListTable;
