import React from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";

const UserVideoInfoModal = ({ open, setOpen, src }) => {
  const videoSrc =
    src ||
    "https://www.youtube.com/embed/3OwwOy0QvEc";

  return (
    <div>
      <Modal
        isOpen={open}
        setIsOpen={setOpen}
        isCentered
        titleId="competition-info-title"
      >
        <ModalHeader>
          <ModalTitle id="competition-info-title">
            <div
              className="d-flex justify-content-center align-items-center m-0"
            >
              <h3
                style={{
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#343a40",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                TALENTCUP YARIŞMA DUYURUSU
              </h3>
            </div>
          </ModalTitle>
          <button
            onClick={() => setOpen(false)}
            style={{
              background: "none",
              border: "none",
              fontSize: "20px",
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
          >
            &times; {/* X işareti */}
          </button>
        </ModalHeader>
        <ModalBody>
          <iframe
            src={videoSrc}
            width="100%"
            height="315"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="video"
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserVideoInfoModal;
