const USER_PAYMENT_PLAN_TYPE = {
    STANDARTMOUNTHLY : {
        id: 1,
        newPrice: '500',
        oldPrice: '600',
    },
    STANDARTYEARLY : {
        id: 2,
        newPrice: '3000',
        oldPrice: '3600',
    },
    PREMIUMMOUNTHLY : {
        id: 3,
        newPrice: '700',
        oldPrice: '800',
    },
    PREMIUMYEARLY : {
        id: 4,
        newPrice: '4200',
        oldPrice: '4800',
    },
};
export default USER_PAYMENT_PLAN_TYPE;