import React, { useEffect } from 'react'
import LeaderBoardLeader from './LeaderBoardLeader'
import LeaderBoardOthers from './LeaderBoardOthers'
import style from "./LeaderBoardForStudent.module.scss";
import { useLazyLeaderBoardsInClassQuery } from '../../../../store/features/apiSlice';

const ClassLeaderBoard = ({classId}) => {
  const [fetchClassLeaderBoard, { data }] = useLazyLeaderBoardsInClassQuery();
  
  // classId değiştiğinde yalnızca bir kez veri çekmek için useEffect kullanıyoruz
  useEffect(() => {
    if (classId) {
      fetchClassLeaderBoard(classId); // classId değiştiğinde sorguyu tetikle
    }
  }, [classId, fetchClassLeaderBoard]); // classId değiştiğinde tetiklenecek
  if (!data) {
    console.log("DATA IS NULL");
    return;
  }

  let user = data && data?.student;
  let leader;
  let others;
  let userControl = 0;

  if (!user && data.length > 0) {
    console.log("USER IS NULL");
    let _leader = data.find((item) => item.rank === 1);
    leader = {
      id: _leader.id,
      avatar: _leader.avatar,
      email: `${_leader.first_name} ${_leader.last_name}`,
      total_points: _leader.points,
    };
  } else {
    if (user.rank === 1) {
      leader = { ...user, email: user.email.split("@")[0] };
      others = data && data.top_10.slice(1, 5);
    } else if (user.rank > 0 && user.rank < 6) {
      let _leader = data && data.top_10[0];
      leader = { ..._leader, email: _leader.email.split("@")[0] };
      others = data && data.top_10.slice(1, 5);
    } else if (user.rank > 5) {
      let _leader = data && data.top_10[0];
      leader = { ..._leader, email: _leader.email.split("@")[0] };
      others = data && data.top_10.slice(1, 4).concat(user);
    }
    if (user) {
      userControl = user.id;
    }
  }
  return (
    <div className={`${style.leaderBoard} row h-100 mt-5`}>
    <div className="col-lg-4 col-md-6">
      <LeaderBoardLeader leader={leader} userControl={userControl}/>
    </div>
    <div className="col-lg-8 col-md-6">
    {others && others.length > 0 && <LeaderBoardOthers others={others} userControl={userControl} />}
    </div>
  </div>
  )
}

export default ClassLeaderBoard