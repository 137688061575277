import style from "./LeaderBoard.module.scss";
import {
  useGetStudentSubscribedClassesQuery,
  useLeaderBoardsMonthlyQuery,
} from "../../../store/features/apiSlice";
import LeaderBoardClassAll from "./LeaderBoardForTeacher/LeaderBoardClassAll";
import USER_ROLES from "../../../common/data/enumUserRole";
import { useState } from "react";
import GeneralLeaderBoard from "./LeaderBoardForStudent/1-GeneralLeaderBoard";
import ClassLeaderBoard from "./LeaderBoardForStudent/2-ClassLeaderBoard";
import lessonsLogo1 from "../../../assets/lessons/lessonsLogo1.svg";
import lessonsLogo2 from "../../..//assets/lessons/lessonsLogo2.svg";
import pathsLogo1 from "../../..//assets/lessons/pathsLogo1.svg";
import pathsLogo2 from "../../..//assets/lessons/pathsLogo2.svg";
import MonthlyGeneralLeaderBoard from "./LeaderBoardForStudent/3-MonthlyGeneralLeaderBoard";

const LeaderBoard = ({ data }) => {
  const { data: studentData } =
    useGetStudentSubscribedClassesQuery();
  const { data: monthlyLeaderBoardData } =
  useLeaderBoardsMonthlyQuery();
    const _activeTab = sessionStorage.getItem("activeTab");
  const [activeTab, setActiveTab] = useState(Number(_activeTab) || 1);

  const userRole = localStorage.getItem("roles");
  const isStudent = Number(userRole) === USER_ROLES.STUDENT.id;

  const subscribedStudent =
    studentData && Object.keys(studentData).length !== 0;

  const classId = studentData && studentData.class_id

  const tabContents = [
    {
      content: <MonthlyGeneralLeaderBoard data={monthlyLeaderBoardData} />,
      tabName: "Son Ay Liderliği",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
    {
      content: <GeneralLeaderBoard data={data} />,
      tabName: "Genel Liderlik",
      icon1: lessonsLogo1,
      icon2: lessonsLogo2,
    },
    {
      content: <ClassLeaderBoard data={data} classId={classId} />,
      tabName: "Sınıf Liderliği",
      icon1: pathsLogo1,
      icon2: pathsLogo2,
    },
  ];

  const activeTabContents = subscribedStudent
    ? tabContents
    : tabContents.slice(0, 2);

  const handleTabClick = (tabNumber) => {
    if (tabNumber >= 1 && tabNumber <= tabContents.length) {
    sessionStorage.setItem("activeTab", tabNumber);
    setActiveTab(tabNumber);}
  };
  return (
    <>
      {isStudent ? (
        <>
          <div
            className={`${style.tabContainer} d-flex justify-content-evenly align-items-center mx-auto px-3 gap-1`}
          >
            {activeTabContents.map((tab, index) => (
              <div
                key={index}
                className={`${style.tab} ${
                  activeTab === index + 1 ? `${style.activetab}` : ""
                } d-flex justify-content-center align-items-center gap-2 p-3`}
                onClick={() => handleTabClick(index + 1)}
              >
                <div className={`${style.tabsLogo}`}>
                  <img
                    src={activeTab === index + 1 ? tab.icon2 : tab.icon1}
                    alt=""
                  />
                </div>
                <div className={`${style.tabsDescription}`}>
                  <span>{tab.tabName}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="tab-content">
            {tabContents[activeTab - 1] && tabContents[activeTab - 1]?.content}
          </div>
        </>
      ) : (
        <>
          <div className={`${style.LeaderBoardClassAllContainer} col-12 px-5`}>
            <LeaderBoardClassAll classStudents={data} />
          </div>
        </>
      )}
    </>
  );
};

export default LeaderBoard;
