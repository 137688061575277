import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeaderTitle from "../../../common/Headers/DashboardHeaderWithTitle";
import SchoolManagerHeader from "./school-manager-header/SchoolManagerHeader";
import SchoolManagerInformation from "./school-manager-information/SchoolManagerInformation";
import ActiveClasses from "./active-classes/ActiveClasses";
import Students from "./students/Students";
import ActiveHomeworks from "./active-homeworks/ActiveHomeworks";
import ActivitiesWrapper from "./activities/ActivitiesWrapper";
import { useGet_a_SchoolQuery } from "../../teacher/teacherApi";

const SchoolManagerDashboard = () => {
  const schoolId = localStorage.getItem("school")
  const { data} = useGet_a_SchoolQuery(schoolId);
  const schoolName = data && data.title

  localStorage.setItem("schoolName",schoolName)

  return (
    <PageWrapper>
      <Page>
	  <DashboardHeaderTitle />
    <div className="row">
      {/* Welcome banner */}
      <SchoolManagerHeader  schoolName={schoolName}/>

      {/* Information */}
      <SchoolManagerInformation schoolId={schoolId}/>

      {/* Active classes and students */}
      <div className="col-12 col-lg-6 mt-5">
          <ActiveClasses />
        </div>
      <div className="col-12 col-lg-6 mt-5">
          <Students />
        </div>
        
      {/* Homeworks and last 10 days activities */}
      <div className="col-12 col-lg-6 mt-5">
          <ActiveHomeworks />
          {/* <Students /> */}
        </div>
      <div className="col-12 col-lg-6 mt-5">
          <ActivitiesWrapper />
        </div>
      
    </div>
	  </Page>
    </PageWrapper>
  );
};

export default SchoolManagerDashboard;
