import React from "react";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import Page from "../../../../layout/Page/Page";
import DashboardHeader from "../../../common/Headers/DashboardHeader";
import Button from "../../../../components/bootstrap/Button";
import { useNavigate } from "react-router";

const Robot14Wrapper = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper title={"CodeArt"}>
      <DashboardHeader />
      <Page container={false} style={{ padding: "0" }}>
        <div className="h-100 d-flex justify-content-center mt-5">
          <div
            style={{ padding: "20px", textAlign: "center" }}
          >
            <h1>Robot14'ün beta sürümüdür.</h1>
            <Button
              style={{
                padding: "10px 20px",
                backgroundColor: "#007BFF",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              className="mt-5"
              onClick={() => navigate("/robot14/game")}
            >
              İncele
            </Button>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default Robot14Wrapper;
