import React, { useEffect } from "react";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../components/bootstrap/Modal";
import style from "./StepGifModal.module.scss";
import gifwrapper from "../../../../assets/games/gifwrappers/TEMELLER1.png";
import uzayWrapper from "../../../../assets/games/gifwrappers/uzayWrapper.png";
import startLogo from "../../../../assets/games/gifwrappers/gifStart.png";
import closeLogo from "../../../../assets/games/gifwrappers/gifClose.png";

import { getModalStepByURL } from "./gameModalFunctions";

const StepGifModal = ({ open, setOpen, currentUrl, onCancel, onClick }) => {
  const modalContent = getModalStepByURL(currentUrl);

  useEffect(() => {
    if (open) {
      const modalContents = document.getElementsByClassName("modal-content");

      // Eğer modalContent2 varsa, her birine backgroundColor uygula
      if (modalContents.length > 0) {
        Array.from(modalContents).forEach((modalContent) => {
          modalContent.style.backgroundColor = "transparent";
        });
      }
    }
  }, [open]); // open state'i değiştiğinde tetiklenir
  return (
    <div className={`${style.stegGifModal}`}>
      <Modal
        isOpen={open}
        setIsOpen={setOpen}
        isCentered
        size="md"
        fullScreen
        titleId="competition-info-title"
      >
        <div className={`${style.modalWrapper}`}>
          <div
            className={`${style.modalContent} p-0`}
            style={{
              backgroundImage: `url(${modalContent.wrapper})`,
            }}
          >
            <div className={`${style.gifContainer}`}>
              <div className={`${style.imageContainer}`}>
                <img
                  className={`${style.responsiveImage}`}
                  src={modalContent.gif}
                  alt={"gif"}
                />
              </div>
            </div>
            {/* <div className={`${style.modalTitle}`}>
              <p className="m-0 p-0">TEMELLER-1</p>
            </div> */}
            <div
            className={`${style.modalTitle}`}
            >
              {`${modalContent.title}`.split("").map((char, index) => (
                <span
                  key={index}
                  style={{
                    display: "inline-block",
                    transform: `rotate(${Math.random() * 20 - 10}deg)`, // -10 ile +10 derece arasında rastgele eğim
                    position: "relative",
                    top: `${Math.random() * 10 - 5}px`, // Harflerin yukarı/aşağı pozisyonu
                  }}
                >
                  {char}
                </span>
              ))}
            </div>

            <div className={`${style.modalDescription}`}>
              <p className="m-0 p-0">
                {modalContent.description}
              </p>
            </div>
            {/* Kapatma İkonu */}
            <div onClick={onCancel} className={`${style.closeIcon}`}>
              <img src={closeLogo} alt="closeIcon" />
            </div>

            {/* Başla Butonu */}
            <div onClick={onClick} className={`${style.startButton}`}>
              <img src={startLogo} alt="startButton" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StepGifModal;
