import React, { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../store/auth-store';
import USER_SUBSCRIPTION_TYPE from '../../common/data/enumUserSubscriptionType';

const ProtectedRoute = ({ children, path }) => {
    const { id,sectionId } = useParams();
    const { user } = useContext(AuthContext);
    const userSubscriptionType = localStorage.getItem("subscription_type");
  const isStandardUser = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.STANDART.id;
  const isFreeUser = Number(userSubscriptionType) === USER_SUBSCRIPTION_TYPE.FREE.id;


  const freeCourses = ["101","13","14","15","179","180","181","182","183","184"]
  const standardCourses = freeCourses.concat(["115","106","19","69","70","71","72","94","105","112","113","114","116","117","118"])
  const freeLearningPaths = ["220"]
  const freePractices = ["1", "2"]
  const freeGames = ["1","7"]
  const freeSections = ["1", "2", "3", "4","37","38", "25"]

  const standardSections = ["32","33","34","35","36"]
//   console.log(user);
  // console.log("ProtectedRouteId",id);
//   console.log("ProtectedRouteSectionId",sectionId);
//   console.log("ProtectedRoute",path);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (path === "lessons" && (!freeCourses.includes(id) && (isFreeUser))) {
    return <Navigate to="/forbidden" />;
  }
  if (path === "lessons" && !standardCourses.includes(id) && isStandardUser) {
    return <Navigate to="/forbidden" />;
  }
  if (path ==="learningPath" && !freeLearningPaths.includes(id) && (isFreeUser || isStandardUser)) {
    return <Navigate to="/forbidden" />;
  }
  if (path ==="pathsPractice" && !freePractices.includes(id) && isFreeUser) {
    return <Navigate to="/forbidden" />;
  }
  if (path ==="games" && !freeGames.includes(id) && isFreeUser) {
    return <Navigate to="/forbidden" />;
  }
  if (path ==="sections" && !freeSections.includes(sectionId) && isFreeUser) {
    return <Navigate to="/forbidden" />;
  }
  if (path ==="sections" && standardSections.includes(sectionId) && isStandardUser) {
    return <Navigate to="/forbidden" />;
  }

  return children;
};

export default ProtectedRoute;
