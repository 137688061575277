import React from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import showNotification from "../../../../../components/extras/showNotification";
import style from "./Heatmap.module.css";
import "./react-calendar-heatmap.css";

function getCustomFormattedDate(inputDate) {
  // Girdi tarihini "gün.ay.yıl" formatından alıp, JavaScript Date nesnesine çevirelim.
  const [day, month, year] = inputDate.split(".");
  const inputDateTime = new Date(`${year}-${month}-${day}T00:00:00`);

  // Tarih ve saat bilgisini uygun formatta döndürelim.
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  const formattedDate = inputDateTime.toLocaleString("en-US", options);
  return formattedDate;
}

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function getDatesBetween(startDate, endDate) {
  const dateArray = [];
  const currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  while (currentDate <= lastDate) {
    dateArray.push(formatDate(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

const Heatmap = ({ year, activeDay }) => {
  const activeDaysDate = [];
  activeDay?.map((item) => {
    return activeDaysDate.push({ date: item, count: 1 });
  });

  let monthsLabels = [
    "Oca",
    "Sub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Aug",
    "Eyl",
    "Eki",
    "Kas",
    "Ara",
  ];
  let weekdayLabels = ["Pzr", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"];

  let startDate;
  let endDate;
  switch (year) {
    case "2023-1":
      startDate = getCustomFormattedDate("30.12.2022");
      endDate = getCustomFormattedDate("01.07.2023");
      break;
    case "2023-2":
      startDate = getCustomFormattedDate("30.06.2023");
      endDate = getCustomFormattedDate("01.01.2024");
      break;
    case "2024-1":
      startDate = getCustomFormattedDate("31.12.2023");
      endDate = getCustomFormattedDate("01.07.2024");
      break;
    case "2024-2":
      startDate = getCustomFormattedDate("30.06.2024");
      endDate = getCustomFormattedDate("01.01.2025");
      break;
    case "2025-1":
      startDate = getCustomFormattedDate("31.12.2024");
      endDate = getCustomFormattedDate("01.07.2025");
      break;
    default:
      break;
  }
  const allDates = getDatesBetween(startDate, endDate);

  const allDaysDate = allDates.map((date) => {
    const count = activeDay && activeDay.includes(date) ? 1 : 0;
    return { date, count };
  });

  return (
    <div>
      <CalendarHeatmap
        startDate={`${startDate}`}
        endDate={`${endDate}`}
        values={allDaysDate}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return "color-empty";
          } else if (value.count === 1) {
            return `${style.test}`;
          }
        }}
        showWeekdayLabels={true}
        onClick={(value) =>
          showNotification(
            <span className="d-flex align-items-center">
              <span>Bilgi</span>
            </span>,
            <p style={{ fontSize: "14px" }}>{value.date}</p>,
            "success",
            {
              container: "top-left",
              dismiss: {
                duration: 3000,
                pauseOnHover: true,
                onScreen: true,
                showIcon: true,
                waitForAnimation: true,
              },
            }
          )
        }
        monthLabels={monthsLabels}
        weekdayLabels={weekdayLabels}
      />
    </div>
  );
};

export default Heatmap;
