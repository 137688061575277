import React, { createContext, useContext, useState } from "react";

const HomeworkContext = createContext();

export const HomeworkProvider = ({ children }) => {
  const [homeworkLessons, setHomeworkLessons] = useState(
    JSON.parse(localStorage.getItem("homeworkLessons")) || []
  );

  const updateHomeworkLessons = (newLessons) => {
    localStorage.setItem("homeworkLessons", JSON.stringify(newLessons));
    setHomeworkLessons(newLessons);
  };

  return (
    <HomeworkContext.Provider value={{ homeworkLessons, updateHomeworkLessons }}>
      {children}
    </HomeworkContext.Provider>
  );
};

export const useHomework = () => useContext(HomeworkContext);
