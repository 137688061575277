import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
// import talent from "../../../src/assets/dashboardImages/talent.png";
import talent from "../../../src/assets/dashboardImages/talent2.webp";

const Brand = ({ asideStatus, setAsideStatus }) => {
	const navigate = useNavigate();
	return (
		<div className='brand'>
			<div className='brand-logo' style={{cursor:"pointer"}} onClick={()=>navigate("/")}>
				{/* <h1 className='brand-title'>
					TALENT14
				</h1> */}
				<div style={{width:"150px"}}>
				<img style={{width:"100%"}} src={talent} alt="" />
				</div>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => {
					setAsideStatus(!asideStatus)}}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close'/>
				<Icon icon='LastPage' color="light" className='brand-aside-toggle-open p-1' style={{backgroundColor:"#6B5CD2", width:"35px", height:"35px", borderRadius:"50%"}}/>
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
