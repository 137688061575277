import React, { useState } from "react";
import style from "./Calendar.module.scss"
import Select from "../../../../../components/bootstrap/forms/Select";
import Heatmap from "./heatmap/Heatmap";

const Calendar = () => {
  const [year, setYear] = useState("2025-1");
  const years = [
    { text: "2023-1", value: "2023-1" },
    { text: "2023-2", value: "2023-2" },
    { text: "2024-1", value: "2024-1" },
    { text: "2024-2", value: "2024-2" },
    { text: "2025-1", value: "2025-1" },
  ];
  const handleDateChange = (e) => {
    setYear(e.target.value);
  };
  return (
    <div className={`${style.calendarCard}`}>
            <div className={`d-flex justify-content-between align-items-center`}>
              <div>
                <h4 className={`${style.title}`}>İlerleme Takvimim</h4>
              </div>
              <Select
                value={year}
                onChange={handleDateChange}
                style={{ width: "100px" }}
                list={years}
                ariaLabel={year}
              ></Select>
            </div>
            <div className="border border-solid p-1 rounded-2">
              <div className={`${style.calendar}`}>
                <Heatmap year={year} />
              </div>
            </div>
          </div>
  );
};

export default Calendar;
