import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import Icon from "../../../../components/icon/Icon";

const HomeworkPractices = ({
  hwPractices,
  homework,
  isActive = false,
  homeworkPractices = [],
}) => {
  const navigate = useNavigate();
  // Veriyi useMemo ile optimize etme
  const memoizedHwPractices = useMemo(() => {
    return hwPractices?.map((lp) => ({
      id: lp.id,
      practices: lp.practices.map((p) => ({
        ...p,
        isSolvedOrInHomework: p.is_solved, // Gerekirse yeni bir özellik eklenebilir.
      })),
    }));
  }, [hwPractices]); // hwPractices değiştiğinde yeniden hesaplanır.

  return (
    <div>
      {memoizedHwPractices?.map((lp) => {
        const { practices, id } = lp;
        return (
          <div key={id} className="practice-group">
            {practices?.map((practice) => (
              <span
                key={practice.id}
                className="step-item"
                style={{
                  color: practice.isSolvedOrInHomework ? "blue" : "black",
                  cursor: "pointer",
                  fontSize: "16px",
                  marginRight: "10px",
                }}
                onClick={() =>
                  navigate(`/practice/${practice.id}?homework=${homework}`)
                }
              >
                {!isActive ? (
                  <>
                    {practice.isSolvedOrInHomework ? (
                      <Icon icon={"check"} color={"success"} size="2x" />
                    ) : (
                      "❓"
                    )}
                  </>
                ) : (
                  <>
                    {homeworkPractices.some(
                      (p) =>
                        p.practice === practice.id &&
                        p.homework === homework
                    ) || practice.isSolvedOrInHomework ? (
                      <Icon icon={"check"} color={"success"} size="2x" />
                    ) : (
                      "❓"
                    )}
                  </>
                )}
              </span>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default HomeworkPractices;
