import React from "react";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "../../../../../components/bootstrap/Modal";
import Logo from "../../../../../components/Logo";
import Icon from "../../../../../components/icon/Icon";
import { useGetStudentHomeworkQuery } from "../../../../../store/features/apiSlice";
import { nanoid } from "nanoid";
import { formatDateTime } from "../../../../../utils/formatDate";
import { useNavigate } from "react-router-dom";

const HomeworkDetailModal = ({
  isOpenHomeworkDetailModal,
  setIsOpenHomeworkDetailModal,
  homeworkId,
}) => {
  const { data } = useGetStudentHomeworkQuery(homeworkId, {
    skip: homeworkId === null,
  });
  const navigate = useNavigate();
  const courseLessonsHomework = data && data.content.course_lessons;
  const schoolCourseLesson = courseLessonsHomework?.filter(
    (item) => item.school_status === 2
  );
  const defaultCourseLesson = courseLessonsHomework?.filter(
    (item) => item.school_status === 1 || item.school_status === 3
  );
  const learningPathsHomework = data && data.content.learning_paths;
  const gameStepsHomework = data && data.content.game_steps;
  const teacherPracticesHomework = data && data.content.teacher_practices;
  const isLearningPaths =
    learningPathsHomework && learningPathsHomework.length > 0;
  const isCourseLessons = defaultCourseLesson && defaultCourseLesson.length > 0;
  const isScoolCourseLessons =
    schoolCourseLesson && schoolCourseLesson.length > 0;
  const isGameSteps = gameStepsHomework && gameStepsHomework.length > 0;
  const isteacherPractices =
    teacherPracticesHomework && teacherPracticesHomework.length > 0;
  return (
    <Modal
      id="example-modal"
      isCentered
      isOpen={isOpenHomeworkDetailModal}
      setIsOpen={setIsOpenHomeworkDetailModal}
      titleId="example-title"
    >
      <ModalHeader setIsOpen={setIsOpenHomeworkDetailModal}>
        <ModalTitle id="tour-title" className="d-flex align-items-end">
          <Logo height={28} /> <span className="ps-2">Ödev Detayı</span>
          <span className="ps-2">
            <Icon icon="Computer" color="info" />
          </span>
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="row gap-3 d-flex justify-content-center">
          <div className="col-lg-10">
            <div>
              <h5>Ödev Başlığı</h5>
              <p>{data && data.title}</p>
            </div>
            <hr />
            <div>
              <h5>Ödev Açıklaması</h5>
              <p>{data && data.description}</p>
            </div>
            <hr />
            <div>
              <h5>Başlangıç ve Bitiş Tarihleri</h5>
              <p>
                {data &&
                  formatDateTime(data.start_date) +
                    " - " +
                    formatDateTime(data.end_date)}
              </p>
            </div>
            <hr />
            <div>
              <h5>Ödev İçeriği</h5>
              {isLearningPaths && (
                <div className="d-flex gap-1">
                  <div>
                    <h6>Alıştırmalar :</h6>
                    <ul>
                      {learningPathsHomework?.map((learningPath) => {
                        const { title, practices } = learningPath;
                        return (
                          <li key={nanoid()}>
                            <span>
                              {title} ,{" "}
                              <ul>
                                {practices.map((practice) => (
                                  <li
                                    key={practice.id}
                                    className="cursor-pointer"
                                    style={{ textDecoration: "underline" }}
                                    onClick={() =>
                                      navigate(
                                        `/practice/${practice.id}?homework=${homeworkId}`
                                      )
                                    }
                                  >
                                    <span
                                      style={{
                                        color: `${
                                          practice.is_solved ? "blue" : null
                                        }`,
                                      }}
                                    >
                                      {practice.title}{" "}
                                      {practice.is_solved && "👌Çözüldü"}
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {isCourseLessons && (
                <div className="d-flex gap-1">
                  <div>
                    <h6>Dersler :</h6>
                    <ul>
                      {defaultCourseLesson?.map((course) => {
                        const { title: courseTitle, chapters } = course;
                        return (
                          <li key={nanoid()}>
                            <ul className="p-0">
                              {<span>{courseTitle}</span>} ,{" "}
                              {chapters?.map((chapter) => {
                                const { title: chapterTitle, lessons } =
                                  chapter;
                                return (
                                  <li key={nanoid()} className="ms-4">
                                    <span>
                                      {chapterTitle} ,{" "}
                                      <ul>
                                        {lessons.map((lesson) => (
                                          <li key={nanoid()}>
                                            <span
                                              className="cursor-pointer"
                                              style={{
                                                textDecoration: "underline",
                                                color: `${
                                                  lesson.is_solved
                                                    ? "blue"
                                                    : null
                                                }`,
                                              }}
                                              onClick={() =>
                                                navigate(
                                                  `/lessons/content/${lesson.id}?homework=${homeworkId}`
                                                )
                                              }
                                            >
                                              {lesson.title}{" "}
                                              {lesson.is_solved && "👌Çözüldü"}
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {isGameSteps && (
                <div className="d-flex gap-1">
                  <div>
                    <h6>Oyunlar :</h6>
                    <ul>
                      {gameStepsHomework?.map((game) => {
                        const { title, sections, id: gameId } = game;
                        return (
                          <li key={nanoid()}>
                            <ul className="p-0">
                              {title} ,{" "}
                              {sections?.map((section) => {
                                const {
                                  title: sectionTitle,
                                  steps,
                                  id: sectionId,
                                } = section;
                                return (
                                  <li key={nanoid()} className="ms-4">
                                    <span>
                                      {sectionTitle}
                                      <ul>
                                        {steps.map((step) => (
                                          <li
                                            key={step.id}
                                            className="cursor-pointer"
                                            style={{
                                              textDecoration: "underline",
                                              color: `${
                                                step.is_solved ? "blue" : null
                                              }`,
                                            }}
                                          >
                                            <a
                                                href={`/games/${gameId}/sections${
                                                  sectionId
                                                    ? "/" + sectionId + "/"
                                                    : "/"
                                                }steps/${
                                                  step.id
                                                }?homework=${homeworkId}`}
                                              >
                                                {step.title}{" "}
                                                {step.is_solved && "👌Çözüldü"}
                                              </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {isScoolCourseLessons && (
                <div className="d-flex gap-1">
                  <div>
                    <h6>Kurum Dersleri :</h6>
                    <ul>
                      {schoolCourseLesson?.map((course) => {
                        const { title: courseTitle, chapters, id } = course;
                        return (
                          <li key={nanoid()}>
                            <ul className="p-0">
                              {
                                <span
                                  className="cursor-pointer"
                                  style={{ textDecoration: "underline" }}
                                  onClick={() =>
                                    navigate(
                                      `/lessons/AllLessonsAndContent/${id}`
                                    )
                                  }
                                >
                                  {courseTitle}
                                </span>
                              }{" "}
                              ,{" "}
                              {chapters?.map((chapter) => {
                                const { title: chapterTitle, lessons } =
                                  chapter;
                                return (
                                  <li key={nanoid()} className="ms-4">
                                    <span>
                                      {chapterTitle} ,{" "}
                                      <ul>
                                        {lessons.map((lesson) => (
                                          <li key={nanoid()}>
                                            <span
                                              className="cursor-pointer"
                                              style={{
                                                textDecoration: "underline",
                                                color: `${
                                                  lesson.is_solved
                                                    ? "blue"
                                                    : null
                                                }`,
                                              }}
                                              onClick={() =>
                                                navigate(
                                                  `/lessons/content/${lesson.id}?homework=${homeworkId}`
                                                )
                                              }
                                            >
                                              {lesson.title}{" "}
                                              {lesson.is_solved && "👌Çözüldü"}
                                            </span>
                                            </li>
                                        ))}
                                      </ul>
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {isteacherPractices && (
                <div className="d-flex gap-1">
                  <div>
                    <h6>Eğitmen Alıştırmaları :</h6>
                    <div>
                      <ul>
                        {teacherPracticesHomework.map((p) => (
                          <li
                            key={p.id}
                            className="cursor-pointer"
                            style={{ textDecoration: "underline" }}
                            onClick={() =>
                              navigate(
                                `paths/teacher/practice/${p.id}?homework=${homeworkId}`
                              )
                            }
                          >
                            <span
                                      style={{
                                        color: `${
                                          p.is_solved ? "blue" : null
                                        }`,
                                      }}
                                    >
                                      {p.title}{" "}
                                      {p.is_solved && "👌Çözüldü"}
                                    </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {(isGameSteps ||
                isLearningPaths ||
                isCourseLessons ||
                isteacherPractices) === false && (
                <span>Ödev içeriğiniz henüz oluşturulmadı.</span>
              )}
            </div>
            <hr />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default HomeworkDetailModal;
