import React, { useEffect } from "react";
import Modal from "../../../../components/bootstrap/Modal";
import style from "./SectionModal.module.scss";
import { getModalContentByURL } from "./gameModalFunctions";

const SectionModal = ({ open, setOpen, onClick, onCancel, currentUrl }) => {
  const modalContent = getModalContentByURL(currentUrl);

  useEffect(() => {
    if (open) {
      const modalContents = document.getElementsByClassName("modal-content");

      // Eğer modalContent2 varsa, her birine backgroundColor uygula
      if (modalContents.length > 0) {
        Array.from(modalContents).forEach((modalContent) => {
          modalContent.style.backgroundColor = "transparent";
        });
      }
    }
  }, [open]); // open state'i değiştiğinde tetiklenir

  return (
    <div>
      <Modal
        isOpen={open}
        setIsOpen={setOpen}
        isCentered
        size="md"
        fullScreen
        titleId="competition-info-title"
      >
        <div className={`${style.modalWrapper}`}>
          <div
            className={`${style.modalContent} p-0`}
            style={{
              backgroundImage: `url(${modalContent.image})`,
            }}
          >
            <div className={`${style.modalTitle}`}>
              <p className="m-0 p-0">{modalContent.title}</p>
            </div>
            <div className={`${style.modalDescription}`}>
              {Array.isArray(modalContent.description) ? (
                modalContent.description.map((item, index) => (
                  <p key={index} className="m-0 p-0">
                    {item}
                  </p>
                ))
              ) : (
                <p className="m-0 p-0">{modalContent.description}</p>
              )}
            </div>
            {/* Kapatma İkonu */}
            <div onClick={onCancel} className={`${style.closeIcon}`}>
              <img src={modalContent.closeIcon} alt="closeIcon" />
            </div>

            {/* Başla Butonu */}
            <div onClick={onClick} className={`${style.startButton}`}>
              <img src={modalContent.startButton} alt="startButton" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SectionModal;
